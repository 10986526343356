import React, { Component } from 'react';
import { Alert, Badge, ButtonGroup, Card, Col, Form, OverlayTrigger, Popover, Ratio, Row, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import RxPlayer from 'rx-player';

import { TooltipedButton } from '../Components/CommonButtons';
import InputComponent from '../Components/InputComponent';
import ctx from '../context';
import { arrayToString, drmSystemInfo, getCdnName, getFormatFromFamily, ToggleFullScreen } from '../utils.js';

const DEFAULT_SUBTITLE = 'fra';
const DEFAULT_AUDIO_TRACK = 'fra';
const NB_MAX_THUMBNAILS = 18;
const RELOAD_TIMER_1_MIN = 60000; //1min

const api = {
  sites: 'routes',
  channels: 'monitoring/config',
  vod: 'routes',
};

const timerList = {
  5: '05 min',
  10: '10 min',
  20: '20 min',
  30: '30 min',
  60: '60 min',
  120: '120 min',
};

function myPopover(title, text) {
  return (
    <Popover>
      <Popover.Header as='h3' className='bg-info text-white'>
        {title}
      </Popover.Header>
      <Popover.Body>
        <b>{text}</b>
      </Popover.Body>
    </Popover>
  );
}

const getSiteStreams = (channels, asset) => channels.filter((item) => item.desc === asset)[0].streams;

const getChannelsStreams = (channels) => {
  return JSON.parse(JSON.stringify(channels).replace(/asset/g, 'name'));
};

const getVodStreams = (content) => content[0].streams;

export class MiniPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playerState: 'unknow',
      currentRepresentation: null,
      log: '',
      focus: false,
      cdn: '',
    };
    this.isHls = this.props.format === 'hls';
    this.format = this.props.format;
    this.queryParams = Object.fromEntries(new URLSearchParams(window.location.search));
    Object.getOwnPropertyNames(MiniPlayer.prototype).map((f) => (this[f] = this[f].bind(this)));
  }

  componentWillUnmount() {
    this.player.dispose();
  }

  componentDidMount() {
    const _self = this;

    //select the player according to video format
    const videoElt = document.getElementById(`videoElt-${this.props.stream.group}-${this.props.stream.name}`);
    this.player = new RxPlayer({
      videoElement: videoElt,
      preferredAudioTracks: [{ language: DEFAULT_AUDIO_TRACK, audioDescription: false }],
      preferredTextTracks: [{ language: DEFAULT_SUBTITLE, closedCaption: false }],
    });

    //add differents events listener
    this.player.addEventListener('error', function (error) {
      _self.printErrorLog(error.message);
      if (error.fatal) {
        //try to reload content when a fatal error occur
        console.log(`${_self.props.stream.name} Error try to reload ...`);
        _self.loadVideo();
      }
    });
    this.player.addEventListener('playerStateChange', this.handlePlayerStateChange);
    this.player.addEventListener('videoTrackChange', this.handleVideoTrackChange);

    //DRM Signalisation listener
    videoElt.addEventListener('webkitneedkey', (evt) => {
      this.xSessionKeyUri = arrayToString(evt.initData).split('skd://')[1];
    });
    //start video
    this.loadVideo();
  }

  handleVideoTrackChange(track) {
    if (track !== null && track.representations.length > 0) {
      const minRepr = track.representations.reduce((acc, r) => (acc.bitrate < r.bitrate ? acc : r));
      this.setState({ currentRepresentation: minRepr });
      this.player.lockVideoRepresentations([minRepr.id]);
    }
  }

  handlePlayerStateChange(state) {
    this.setState({ playerState: state });
    if (state === 'LOADED') {
      this.setState({ cdn: getCdnName(this.player.getContentUrls()[0]) });
    }
  }

  printErrorLog(text) {
    console.log(`Player [${this.props.stream.name}] ${text}`);
    this.setState({ log: text });
  }

  onMouseOver() {
    this.player.unMute();
    this.setState({ focus: true });
  }

  onMouseOut() {
    this.player.mute();
    this.setState({ focus: false });
  }

  handlePlay(play) {
    if (play) {
      this.player.play();
    } else {
      this.player.pause();
    }
  }

  async getLicense(challenge, contentId, drm, drmserviceid, licenseVersion) {
    let licenseUri = this.props.licenseServerUri.get(licenseVersion) ?? this.props.licenseServerUri.get('2');
    licenseUri = this.queryParams.license ?? `${licenseUri}/${drm}`;

    const qsParams = {};
    if (contentId !== undefined) {
      const id = contentId.split('/');
      qsParams.rule = id[id.length - 1];
    }
    qsParams.serviceId = drmserviceid;
    if (this.props.drmId) {
      qsParams.drmId = this.props.drmId;
    }
    licenseUri += `?${Object.entries(qsParams)
      .map(([k, v]) => `${k}=${v}`)
      .join('&')}`;
    const opts = {
      method: 'POST',
      body: challenge,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    };

    try {
      const response = await fetch(licenseUri, opts);
      if (response.ok) {
        const buffer = await response.arrayBuffer();
        return drm !== 'FairPlay' ? buffer : new Uint8Array(buffer);
      } else {
        return Promise.reject({
          noRetry: true,
          message: `getLicense error ${response.statusText}${await response.text()}`,
          fallbackOnLastTry: true,
        });
      }
    } catch (error) {
      return Promise.reject({ noRetry: true, message: `getLicense fetch error ${error.message}`, fallbackOnLastTry: true });
    }
  }

  loadVideo() {
    const options = {
      url: this.props.stream.url,
      transport: this.props.format,
      autoPlay: true,
    };

    const _self = this;

    if (!this.isHls) {
      //Manage Key Systems option
      const fallbackOnValue = {
        onKeyInternalError: 'fallback',
        onKeyOutputRestricted: 'fallback',
      };

      options.lowLatencyMode = false;
      options.transportOptions = { aggressiveMode: false };

      options.keySystems = [];
      Object.values(drmSystemInfo).forEach((drm) => {
        const obj = {
          type: drm.domain,
          getLicense: (challenge) =>
            _self.getLicense(
              challenge,
              undefined,
              drm.name,
              this.props.DRMServiceId || this.props.stream.drmserviceid,
              this.props.licenseVersion || this.props.stream.licenseversion
            ),
          ...fallbackOnValue,
          serverCertificate: drm.name === 'Widevine' ? _self.props.certificat : undefined,
        };
        options.keySystems.push(obj);
      });
    } else {
      //HLS
      options.startAt = { fromLastPosition: 0 };
      options.transport = 'directfile';
      options.keySystems = [
        {
          type: 'fairplay',
          getLicense: (challenge, contentId) =>
            _self.getLicense(
              challenge,
              contentId,
              'FairPlay',
              this.props.DRMServiceId || this.props.stream.drmserviceid,
              this.props.licenseVersion || this.props.stream.licenseversion
            ),
          serverCertificate: this.props.certificat,
        },
      ];
      options.preferredAudioTracks = [{ language: DEFAULT_AUDIO_TRACK, audioDescription: false }];
      options.preferredTextTracks = [{ language: DEFAULT_SUBTITLE, closedCaption: false }];
    }

    this.player.loadVideo(options);
    this.player.mute();
  }

  render() {
    const playerState = this.state.playerState;
    const panelStyle = this.state.focus
      ? 'primary'
      : playerState === 'LOADING' || playerState === 'PAUSED'
      ? 'secondary'
      : playerState === 'PLAYING'
      ? 'success'
      : 'danger';
    const volumeIcon = this.state.focus ? 'fas fa-volume-up' : 'fas fa-volume-mute';
    const popOverTitle = `${this.state.playerState} ${this.state.cdn}`;
    const drmId = this.props.drmId ? `&drmId=${this.props.drmId}` : '';

    return (
      <Card bg='light' border={panelStyle} className='m-xs' onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>
        <Card.Header className={`bg-${panelStyle} text-white  p-t-xs p-b-xs`}>
          <Stack direction='horizontal' gap='1'>
            <span className='text-cut'>{this.props.stream.name}</span>
            <span className={`ms-auto ${volumeIcon}`} />
          </Stack>
        </Card.Header>
        <OverlayTrigger placement='top' overlay={myPopover(popOverTitle, this.state.log)}>
          <Card.Body className='p-xs m-xs'>
            <Link
              to={`/player?format=${this.props.format}&media=${encodeURIComponent(this.props.stream.url)}&asset=${
                this.props.stream.name
              }&DRMServiceId=${this.props.DRMServiceId || this.props.stream.drmserviceid}${drmId}&licenseVersion=${
                this.props.licenseVersion || this.props.stream.licenseversion
              }`}>
              <Ratio aspectRatio='16x9'>
                <video id={`videoElt-${this.props.stream.group}-${this.props.stream.name}`} />
              </Ratio>
            </Link>
          </Card.Body>
        </OverlayTrigger>
        <Card.Footer>
          <span>{this.state.playerState}</span>
          {this.state.currentRepresentation && <span> {this.state.currentRepresentation.bitrate / 1e3} kbps</span>}
          <span> {this.state.cdn}</span>
        </Card.Footer>
      </Card>
    );
  }
}

class Mosaique extends Component {
  static contextType = ctx;
  constructor(props) {
    super(props);
    this.state = {
      isRoutesLoaded: false,
      isCertLoaded: false,
      title: '',
      isPlaying: true,
      autoReload: true,
      isFullScreen: false,
      showFullScreenAlert: false,
      page: 1,
    };
    this.queryParams = Object.fromEntries(new URLSearchParams(window.location.search));
    this.DRMServiceId = this.queryParams.DRMServiceId;
    this.licenseVersion = this.queryParams.licenseVersion;
    this.format = getFormatFromFamily(this.queryParams.family);
    this.isHls = this.format === 'hls';
    this.drmId = this.queryParams.drmId;
    this.mode = this.queryParams.mode ?? 'sites';
    Object.getOwnPropertyNames(Mosaique.prototype).map((f) => (this[f] = this[f].bind(this)));
  }
  async componentDidMount() {
    const title =
      this.mode === 'sites' || this.mode === 'vod'
        ? `${this.queryParams.zone} ${this.queryParams.family} [${this.queryParams.asset}]`
        : `${this.queryParams.stream} ${this.queryParams.filter} ${this.queryParams.family}`;

    let timerValue = Object.keys(timerList)[1];

    const val = parseInt(this.queryParams.timer);
    if (!Number.isNaN(val)) {
      timerValue = val;
    }

    this.setState({
      title,
      timerValue,
    });

    //Get Certificate
    const serverCertificateUrl = this.isHls
      ? 'https://secure-webtv-static.canal-plus.com/bourbon/cert/fps-mycanal-1-20161208.der'
      : 'https://secure-webtv-static.canal-plus.com/widevine/cert/cert_license_widevine_com.bin';

    //Get Certificate
    try {
      const respCertificat = await fetch(serverCertificateUrl);
      if (!respCertificat.ok) {
        throw new TypeError(`getcertificat error ${respCertificat.statusText} (${respCertificat.status})`);
      }
      this.certificat = await respCertificat.arrayBuffer();
      this.setState({ isCertLoaded: true });
    } catch (error) {
      const text = `getcertificat fetch error ${error.message}`;
      console.log(text);
      this.setState({ error: text });
    }

    //Get Channel's streams
    const configurationUrl = `/api/${api[this.mode]}${window.location.search}`;

    try {
      const respConf = await fetch(configurationUrl, { credentials: 'include' });
      if (!respConf.ok) {
        throw new TypeError(respConf.statusText);
      }
      const result = await respConf.json();
      this.allStreams =
        this.mode === 'sites'
          ? getSiteStreams(result.channels, this.queryParams.asset)
          : this.mode === 'channels'
          ? getChannelsStreams(result.channels)
          : getVodStreams(result.channels);

      this.setState({
        isRoutesLoaded: true,
      });

      this.maxPage = Math.ceil(this.allStreams.length / NB_MAX_THUMBNAILS);
      this.updateMosaicChannelsList(this.state.page);
    } catch (error) {
      const text = `get Configuration fetch error :  ${error.message}`;
      console.log(text);
      this.setState({ error: text });
    }

    if (this.state.autoReload) {
      this.startTimer(timerValue);
    }
  }

  componentWillUnmount() {
    this.stopTimer();
    document.body.style.backgroundColor = 'white';
  }

  handlePlay() {
    if (!this.state.isRoutesLoaded) {
      return;
    }

    this.setState(function (prevState) {
      const newState = !prevState.isPlaying;
      const self = this;

      prevState.streams.forEach(function (stream) {
        self.refs[`miniPlayerRef-${stream.group}-${stream.name}`].handlePlay(newState);
      });

      return { isPlaying: newState };
    });
  }

  handleReload() {
    window.location.reload();
  }

  //Manage timer to reload full page
  timer() {
    window.location.reload();
  }

  startTimer(value) {
    this.intervalHandle = setTimeout(this.timer, value * RELOAD_TIMER_1_MIN);
  }
  stopTimer() {
    clearInterval(this.intervalHandle);
  }

  updateMosaicChannelsList(page) {
    const firstThumbnails = (page - 1) * NB_MAX_THUMBNAILS;
    this.setState({ streams: this.allStreams.slice(firstThumbnails, firstThumbnails + NB_MAX_THUMBNAILS) });
  }

  handleNextPage(next) {
    let nextPage;
    if (next) {
      nextPage = this.state.page < this.maxPage ? this.state.page + 1 : 1;
    } else {
      nextPage = this.state.page > 1 ? this.state.page - 1 : this.maxPage;
    }

    this.setState({ page: nextPage });
    this.updateMosaicChannelsList(nextPage);
  }

  createMiniPlayer(stream, index, thumbnailsSize) {
    return (
      <Col sm={thumbnailsSize} key={`${stream.group}-${stream.name}`}>
        <MiniPlayer
          key={stream.name}
          ref={`miniPlayerRef-${stream.group}-${stream.name}`}
          stream={stream}
          index={index}
          certificat={this.certificat}
          format={this.format}
          licenseServerUri={this.context.licenseServerUri}
          location={window.location}
          drmId={this.drmId}
          DRMServiceId={this.DRMServiceId}
          licenseVersion={this.licenseVersion}
        />
      </Col>
    );
  }

  getMosaiqueRows() {
    const streams = this.state.streams;

    //size of thumbnails
    const thumbnailsSize = streams.length <= 2 ? 6 : streams.length <= 6 ? 4 : streams.length <= 12 ? 3 : 2;
    //nb of thumbnails per row (max row = 12)
    const modulo = 12 / thumbnailsSize;

    let line = 0;
    const _self = this;
    const rows = [];
    streams.forEach(function (stream, index) {
      if (index % modulo === 0) {
        line++;
        rows[line] = [];
      }
      rows[line].push(_self.createMiniPlayer(stream, index, thumbnailsSize));
    });

    return rows;
  }

  handleCheckBox() {
    const reload = !this.state.autoReload;
    this.setState({ autoReload: reload });

    if (reload) {
      this.startTimer(this.state.timerValue);
    } else {
      this.stopTimer();
    }
  }

  handleFullScreen() {
    if (this.state.autoReload) {
      this.setState({ showFullScreenAlert: true });
    } else {
      const fs = ToggleFullScreen('MosaicBackground');
      this.setState({ isFullScreen: fs });
    }
  }
  handleTimerSelection(value) {
    this.stopTimer();
    this.setState({ timerValue: value });
    if (this.state.autoReload) {
      this.startTimer(value);
    }

    const rootUrl = window.location.search.replace(/&timer=.*/gi, '');

    this.props.history.push({
      pathname: '/mosaique',
      search: `${rootUrl}&${new URLSearchParams({ timer: value }).toString()}`,
    });
  }

  render() {
    const { error, title, isRoutesLoaded, isPlaying, showFullScreenAlert } = this.state;
    const playButtonStyle = `fas ${isPlaying ? 'fa-pause' : 'fa-play'}`;

    if (error) {
      return <Alert variant='danger'>Error ! {error}</Alert>;
    }
    let rows = null;
    if (this.state.streams) {
      rows = this.getMosaiqueRows();
    }

    return (
      <Card border='dark' id='MosaicBackground'>
        <Card.Header className='text-primary p-b-xs p-t-xs'>
          <Stack direction='horizontal' gap='3'>
            <h4 className='mb-0'>
              <Badge>{title}</Badge>
              {this.maxPage && (
                <Badge bg='dark' text='light' className='ms-3'>
                  Page {this.state.page} / {this.maxPage}
                </Badge>
              )}
            </h4>
            <TooltipedButton
              text='Click to enable / disable autoreload'
              variant={this.state.autoReload ? 'outline-success' : 'warning'}
              onClick={this.handleCheckBox}
              className='ms-auto'>
              <i className={this.state.autoReload ? 'fas fa-sync rotate' : 'fas fa-circle-xmark'} /> Autoreload{' '}
              {this.state.autoReload ? <b>enabled</b> : <b>disabled</b>}
            </TooltipedButton>
            <Col md='2'>
              <InputComponent text='Interval'>
                <Form.Select
                  disabled={!this.state.autoReload}
                  value={this.state.timerValue}
                  onChange={(evt) => this.handleTimerSelection(evt.target.value)}>
                  {Object.entries(timerList).map(([k, v]) => (
                    <option key={k} value={k}>
                      {v}
                    </option>
                  ))}
                </Form.Select>
              </InputComponent>
            </Col>
            <ButtonGroup>
              <TooltipedButton text='Press F11 to fullscreen' onClick={this.handleFullScreen}>
                <i className='fas fa-expand' />
              </TooltipedButton>
              <TooltipedButton text='Play / Pause' onClick={this.handlePlay}>
                <i className={playButtonStyle} />
              </TooltipedButton>
              <TooltipedButton text='Reload all' onClick={this.handleReload}>
                <i className='fas fa-redo' />
              </TooltipedButton>
              {this.maxPage > 1 && (
                <>
                  <TooltipedButton text='previous page' onClick={() => this.handleNextPage(false)}>
                    <i className='fas fa-fast-backward' />
                  </TooltipedButton>
                  <TooltipedButton text='next page' onClick={() => this.handleNextPage(true)}>
                    <i className='fas fa-fast-forward' />
                  </TooltipedButton>
                </>
              )}
            </ButtonGroup>
          </Stack>
        </Card.Header>
        <Card.Body className='p-xs'>
          <Alert show={showFullScreenAlert} variant='warning' onClose={() => this.setState({ showFullScreenAlert: false })} dismissible>
            Press F11 to enter / exit FullScreen or disable autoReload functionnality !
          </Alert>
          {isRoutesLoaded &&
            rows &&
            rows.map((elt, index) => (
              <Row className='mb-1' key={index}>
                {elt}
              </Row>
            ))}
        </Card.Body>
      </Card>
    );
  }
}

export default Mosaique;

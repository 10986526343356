import React, { useEffect, useMemo, useState } from 'react';
import { Badge, ButtonGroup, Form, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { buildQuery, showPlayButton, useQueryParams } from '../utils';
import { AnalysisButton, CopyButton, LinkButton, Tooltiped } from './CommonButtons';
import CCard from './CommonCards';
import InputComponent from './InputComponent';

const regScotty =
  /https?:\/\/(?<origin>.*)\.(?<env>p|r)-cdn(?<mode>live|vod)-edge(?<site>\d{2})(?<box>\d{2})(?<server>\d{2})(?<isDual>-dual)?\.scy\.(?<domain>canalplus|canalbis)-cdn\.net/;

const regsPlm = {
  v1: {
    reg: /\/plm\/(?<conductorId>[^/]*)\/(?<sessionId>[^/]*)\//,
    replacePath: (conductorId, sessionId) => `/plm/${conductorId}/${sessionId}/`,
  },
  v2: {
    reg: /\/composer\/channels\/(?<conductorId>[^/]*)\/sessions\/(?<sessionId>[^/]*)\//,
    replacePath: (conductorId, sessionId) => `/composer/channels/${conductorId}/sessions/${sessionId}/`,
  },
};

const regCft = /https?:\/\/(?<origin>.+)\.live-cft.canal(?<env>plus|bis)-cdn.net/;

const environments = {
  p: {
    label: 'Production',
    domain: 'canaplus',
  },
  r: {
    label: 'Recette',
    domain: 'canalbis',
  },
};

function ScottyUrl(props) {
  const { media, format, asset, DRMServiceId, licenseVersion } = useQueryParams();
  const [url, setUrl] = useState(props.url);
  const groups = useMemo(() => regScotty.exec(props.url).groups, [props.url]);
  const { origin, isDual } = groups;
  const [mode, setMode] = useState(groups.mode);
  const [env, setEnv] = useState(groups.env);
  const [site, setSite] = useState(groups.site);
  const [box, setBox] = useState(groups.box);
  const [server, setServer] = useState(groups.server);
  const [domain, setDomain] = useState(groups.domain);
  const [query, setQuery] = useState(null);

  useEffect(() => {
    setQuery(
      buildQuery({
        media: url,
        format,
        asset,
        DRMServiceId,
        licenseVersion,
      })
    );
  }, [url, format, asset, DRMServiceId, licenseVersion]);

  useEffect(() => {
    setUrl((prev) => {
      const obj = new URL(prev);
      obj.hostname = `${origin}.${env}-cdn${mode}-edge${site}${box}${server}${isDual || ''}.scy.${domain}-cdn.net`;
      return obj.href;
    });
  }, [origin, env, mode, site, box, server, domain, isDual]);

  function onChangeEnv(evt) {
    const { value } = evt.target;
    setEnv(value);
    setDomain(environments[value].domain);
    if (value === 'r') {
      setMode('live');
      setSite('01');
      setBox('01');
    }
  }

  return (
    <Stack gap='3'>
      <Stack direction='horizontal' gap='3'>
        <b>Environment:</b>
        {Object.entries(environments).map(([prefix, { label }]) => (
          <Form.Check key={prefix} type='radio' value={prefix} label={label} checked={env === prefix} onChange={onChangeEnv} />
        ))}
        <InputComponent text='Mode'>
          <Form.Select value={mode} onChange={(evt) => setMode(evt.target.value)}>
            <option>live</option>
            <option>vod</option>
          </Form.Select>
        </InputComponent>
        <InputComponent text='Box'>
          <Form.Control value={box} onChange={(evt) => setBox(evt.target.value)} />
        </InputComponent>
        <InputComponent text='Server'>
          <Form.Control value={server} onChange={(evt) => setServer(evt.target.value)} />
        </InputComponent>
      </Stack>
      <Stack direction='horizontal' gap='1'>
        <InputComponent text='URL'>
          <Form.Control type='text' value={url} onChange={(evt) => setUrl(evt.target.value)} />
        </InputComponent>
        <ButtonGroup>
          <Tooltiped text='play stream'>
            <LinkButton to={`/player?${query}`} disabled={!showPlayButton(media)} reloadDocument>
              <i className='fas fa-play' />
            </LinkButton>
          </Tooltiped>
          <CopyButton text={url} />
        </ButtonGroup>
      </Stack>
    </Stack>
  );
}

function OtherUrl(props) {
  const { format, asset, DRMServiceId, licenseVersion } = useQueryParams();
  const [url, setUrl] = useState(props.url);
  const [query, setQuery] = useState(null);

  useEffect(() => {
    setQuery(
      buildQuery({
        media: url,
        format,
        asset,
        DRMServiceId,
        licenseVersion,
      })
    );
  }, [url, format, asset, DRMServiceId, licenseVersion]);

  return (
    <Stack direction='horizontal' gap='1'>
      <InputComponent text='URL'>
        <Form.Control type='text' value={url} onChange={(evt) => setUrl(evt.target.value)} />
      </InputComponent>
      <ButtonGroup>
        <Tooltiped text='play stream'>
          <LinkButton to={`/player?${query}`} reloadDocument>
            <i className='fas fa-play' />
          </LinkButton>
        </Tooltiped>
        <CopyButton text={url} />
      </ButtonGroup>
    </Stack>
  );
}

function CftUrl(props) {
  const groups = useMemo(() => regCft.exec(props.url).groups, [props.url]);
  const [env, setEnv] = useState(groups.env);
  const [url, setUrl] = useState(props.url);
  const [query, setQuery] = useState(null);
  const qs = useQueryParams();

  useEffect(() => {
    setUrl((prev) => {
      const obj = new URL(prev);
      obj.hostname = `${groups.origin}.live-cft.canal${env}-cdn.net`;
      return obj.href;
    });
  }, [groups.origin, env]);

  useEffect(() => {
    setQuery(buildQuery({ ...qs, media: url }));
  }, [url, qs]);

  return (
    <Stack gap='3'>
      <Stack direction='horizontal' gap='3'>
        <b>Environment:</b>
        <Form.Check type='radio' value='plus' label='Prod' checked={env === 'plus'} onChange={() => setEnv('plus')} />
        <Form.Check type='radio' value='bis' label='PréProd' checked={env === 'bis'} onChange={() => setEnv('bis')} />
      </Stack>
      <Stack direction='horizontal' gap='1'>
        <InputComponent text='URL'>
          <Form.Control type='text' value={url} onChange={(evt) => setUrl(evt.target.value)} />
        </InputComponent>
        <ButtonGroup>
          <Tooltiped text='play stream'>
            <LinkButton to={`/player?${query}`} disabled={!showPlayButton(qs.media)} reloadDocument>
              <i className='fas fa-play' />
            </LinkButton>
          </Tooltiped>
          <CopyButton text={url} />
        </ButtonGroup>
      </Stack>
    </Stack>
  );
}

function PLMUrl(props) {
  const groups = useMemo(() => props.reg.exec(props.url).groups, [props.url, props.reg]);
  const qs = useQueryParams();
  const [query, setQuery] = useState(null);
  const [url, setUrl] = useState(props.url);
  const [conductorId, setConductorId] = useState(groups.conductorId);
  const [sessionId, setSessionId] = useState(groups.sessionId);

  useEffect(() => {
    setUrl((prev) => {
      const obj = new URL(prev);
      obj.pathname = obj.pathname.replace(props.reg, props.replacePath(conductorId, sessionId));
      return obj.href;
    });
  }, [conductorId, sessionId, props]);

  useEffect(() => {
    setQuery(buildQuery({ ...qs, media: url }));
  }, [url, qs]);

  return (
    <Stack gap='3'>
      <Stack direction='horizontal' gap='1'>
        <InputComponent text='Conductor ID'>
          <Form.Control value={conductorId} onChange={(evt) => setConductorId(evt.target.value)} />
        </InputComponent>
        <CopyButton text={conductorId} />
      </Stack>
      <Stack direction='horizontal' gap='1'>
        <InputComponent text='Session ID'>
          <Form.Control value={sessionId} onChange={(evt) => setSessionId(evt.target.value)} />
        </InputComponent>
        <CopyButton text={sessionId} />
      </Stack>
      <Stack direction='horizontal' gap='1'>
        <InputComponent text='URL'>
          <Form.Control value={url} onChange={(evt) => setUrl(evt.target.value)} />
        </InputComponent>
        <ButtonGroup>
          <Tooltiped text='play stream'>
            <LinkButton to={`/player?${query}`} disabled={!showPlayButton(url)} reloadDocument>
              <i className='fas fa-play' />
            </LinkButton>
          </Tooltiped>
          <AnalysisButton as={Link} to={`/mpd?${query}`} />
          <CopyButton text={url} />
        </ButtonGroup>
      </Stack>
    </Stack>
  );
}

function Main({ url, cdn }) {
  let Comp;
  const props = { url };
  if (regScotty.test(url)) {
    Comp = ScottyUrl;
  } else if (regsPlm.v1.reg.test(url)) {
    Comp = PLMUrl;
    Object.assign(props, regsPlm.v1);
  } else if (regsPlm.v2.reg.test(url)) {
    Comp = PLMUrl;
    Object.assign(props, regsPlm.v2);
  } else if (regCft.test(url)) {
    Comp = CftUrl;
  } else {
    Comp = OtherUrl;
  }
  return (
    <CCard open border='dark' title={<b className='text-primary'>URL infos</b>} rightHeader={<Badge className='float-end'>{cdn}</Badge>}>
      <Comp {...props} />
    </CCard>
  );
}

export default Main;

import React, { useEffect, useState } from 'react';
import { Alert, Container, Nav, Navbar, NavDropdown, Spinner } from 'react-bootstrap';
import { BrowserRouter, Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import RxPlayer from 'rx-player';

import GlobalContext from './context';
import HomeMonitoringPlayers from './Home/HomeMonitoringPlayers';
import HomePlayers from './Home/HomePlayers';
import HomeServices from './Home/HomeServices';
import HomeShortcuts from './Home/HomeShortcuts';
import MonitoringPlayer from './Players/MonitoringPlayer';
import Mosaique from './Players/Mosaique';
import WebTvToolsPlayer from './Players/WebTvToolsPlayer';
import Channels from './Services/Channels';
import M3u8Chunk from './Services/M3u8Chunk';
import M3u8Master from './Services/M3u8Master';
import ManifestChunks from './Services/ManifestChunks';
import ManifestMain from './Services/ManifestMain';
import MpdChunks from './Services/MpdChunks';
import MpdMain from './Services/MpdMain';
import VODTools from './Services/VODTools';
import { httpGet } from './utils';

const defaultRoute = '/home/monitoring';

const routes = [
  { path: '/home/monitoring', component: HomeMonitoringPlayers },
  { path: '/home/players', component: HomePlayers },
  { path: '/home/shortcuts', component: HomeShortcuts },
  { path: '/home/services', component: HomeServices },
  { path: '/vod-tools', component: VODTools },
  { path: '/player', component: WebTvToolsPlayer },
  { path: '/routes/:subdir/:zone/:family', component: Channels },
  { path: '/manifest', component: ManifestMain },
  { path: '/manifest/chunks', component: ManifestChunks },
  { path: '/m3u8/master', component: M3u8Master },
  { path: '/m3u8/media', component: M3u8Chunk },
  { path: '/mpd', component: MpdMain },
  { path: '/mpd/chunks', component: MpdChunks },
  { path: '/mosaique', component: Mosaique },
  { path: '/monitoring-player', component: MonitoringPlayer },
];

const menuItems = [
  { href: '/home/monitoring', label: 'Monitoring Players' },
  { href: '/home/players', label: 'Adaptive Streaming Players' },
  { href: '/home/services', label: 'Live services' },
  { href: '/vod-tools', label: 'VOD contents' },
  { href: '/home/shortcuts', label: 'Shortcuts' },
];

function MyNavBar(props) {
  const { pathname } = useLocation();
  const [active, setActive] = useState(pathname);

  useEffect(() => setActive(pathname), [pathname]);

  return (
    <Navbar bg='dark' variant='dark' fixed='top' expand='lg' collapseOnSelect>
      <Container fluid>
        <NavDropdown title={<i className='fas fa-bars' />} id='burger' menuVariant='dark' className='text-secondary me-2'>
          <NavDropdown.Item href='https://webtv-tools.canalplus-bo.net'>
            <i className={props.instance === 'prod' ? 'fas fa-angle-double-right' : 'fa fa-fw'}>&nbsp;</i> Production
          </NavDropdown.Item>
          <NavDropdown.Item href='https://webtv-tools-staging.canalplus-bo.net'>
            <i className={props.instance === 'staging' ? 'fas fa-angle-double-right' : 'fa fa-fw'}>&nbsp;</i> Staging
          </NavDropdown.Item>
          <NavDropdown.Item href='https://webtv-tools.canalbis-bo.net'>
            <i className={props.instance === 'validation' ? 'fas fa-angle-double-right' : 'fa fa-fw'}>&nbsp;</i> Validation
          </NavDropdown.Item>
        </NavDropdown>
        <Navbar.Brand>
          WebTv Tools{' '}
          <small>
            <i className='fas fa-tv' /> <i className='fas fa-tools' /> {props.instance !== 'prod' && `(${props.instance})`}
          </small>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav>
            {menuItems.map((elt) => (
              <Nav.Link
                eventKey={elt.href}
                as={Link}
                key={elt.href}
                to={elt.href}
                active={elt.href === active}
                onClick={(evt) => setActive(evt.target.pathname)}>
                {elt.label}
              </Nav.Link>
            ))}
          </Nav>
          <Nav className='ms-auto'>
            <Navbar.Text className='me-4'>
              <i className='fas fa-user' /> {props.username}
            </Navbar.Text>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

function App() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    httpGet(
      '/api/globalconfig',
      (conf) => setConfig({ ...conf, licenseServerUri: new Map(conf.licenseServerUri) }),
      setError,
      () => setLoading(false)
    );
  }, []);

  return (
    <Container fluid>
      {loading && <Spinner variant='primary' />}
      {error && <Alert variant='danger'>{error}</Alert>}
      {config && (
        <>
          <BrowserRouter>
            <GlobalContext.Provider value={config}>
              <MyNavBar instance={config.instance} username={config.username} />
              <div className='mt-2'>
                <Routes>
                  {routes.map(({ path, component: C }) => (
                    <Route key={path} path={path} element={<C />} />
                  ))}
                  <Route path='*' element={<Navigate to={defaultRoute} />} />
                </Routes>
              </div>
            </GlobalContext.Provider>
          </BrowserRouter>
          <footer className='text-center mt-4'>
            <p>
              v{process.env.REACT_APP_APP_VERSION} (RxPlayer v{RxPlayer.version}) - Made with <i className='fas fa-heart' /> by
              <b> OTT Delivery</b>
            </p>
          </footer>
        </>
      )}
    </Container>
  );
}

export default App;

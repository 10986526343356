import React from 'react';
import { Badge, Stack, Table } from 'react-bootstrap';

import { CopyButton, Tooltiped } from './CommonButtons';
import CCard from './CommonCards';

const cursor = { cursor: 'pointer' };
const color_dai = { color: 'green' };

function PeriodComponent({ periods, callbackParent, availabilityStartTime, currentPeriod }) {
  if (currentPeriod === undefined) {
    return null;
  }

  return (
    <CCard
      open
      border='dark'
      title={<b className='text-primary'>{periods.length} Periods</b>}
      rightHeader={
        <Stack className='float-end' direction='horizontal' gap='2'>
          <Badge bg='success'>{currentPeriod.id}</Badge>
          <Badge bg='success'>{currentPeriod.schemeIdUri}</Badge>
        </Stack>
      }>
      <Table size='sm' hover>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>ID</th>
            <th>schemeIdUri</th>
            <th>Start</th>
          </tr>
        </thead>
        <tbody>
          {periods.map((p) => {
            const time = new Date((availabilityStartTime + p.start) * 1e3);
            const sameId = p.id === currentPeriod.id;
            const isDai = p.schemeIdUri.includes('dai');
            return (
              <tr style={cursor} onClick={() => callbackParent(p.start)} className={sameId ? 'table-primary' : null} key={p.id}>
                <td>
                  <CopyButton text={p.id} size='sm' />
                </td>
                <td className='fs-5'>
                  <i className={sameId ? 'fas fa-play' : 'fas fa-fast-backward'} style={isDai ? color_dai : null} />
                </td>
                <td>
                  <Tooltiped text={p.id}>
                    <span>{p.id.slice(0, 25)}</span>
                  </Tooltiped>
                </td>
                <td>{isDai ? <b>{p.schemeIdUri}</b> : p.schemeIdUri}</td>
                <td>{`${time.toLocaleString()}:${String(time.getMilliseconds()).padStart(3, '0')}`}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </CCard>
  );
}

export default PeriodComponent;

import clsx from 'clsx';
import React, { useState } from 'react';
import { Badge, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';

export function Tooltiped({ text, placement = 'top', ...props }) {
  return <OverlayTrigger {...props} placement={placement} overlay={<Tooltip>{text}</Tooltip>} />;
}

export function TooltipedButton({ text, variant = 'outline-primary', ...props }) {
  return (
    <Tooltiped text={text}>
      <Button variant={variant} {...props} />
    </Tooltiped>
  );
}

export function DownloadButton({ children, ...props }) {
  return (
    <TooltipedButton text='download' {...props}>
      <i className='fas fa-download' /> {children}
    </TooltipedButton>
  );
}

export function AnalysisButton({ children, ...props }) {
  return (
    <TooltipedButton text='analysis' {...props}>
      <i className='fas fa-circle-info' /> {children}
    </TooltipedButton>
  );
}

export function CopyButton({ text, children, copyText = 'copy to clipboard', variant = 'outline-primary', ...props }) {
  const [tttext, setTtText] = useState(copyText);

  function onClick(evt) {
    evt.stopPropagation();
    setTtText('copied !');
    setTimeout(() => setTtText(copyText), 1e3);
  }

  return (
    <Tooltiped text={tttext}>
      <CopyToClipboard text={text}>
        <Button {...props} variant={variant} onClick={onClick}>
          <i className='fas fa-copy' /> {children}
        </Button>
      </CopyToClipboard>
    </Tooltiped>
  );
}

// don't use it everywhere since its interface is quite bad.
// I'm forced to do this because it's forbidden to put <a> inside a button and vice versa
// It might still works, but passing additonnal properties to your <Button> such as 'disabled' won't work
function MyLinkButton({ disabled, variant = 'outline-primary', size, ...props }) {
  return <Link {...props} role='button' className={clsx('btn', `btn-${variant}`, disabled && 'disabled', size && `btn-${size}`)} />;
}

// discard ref
// forced to create random dom node for tooltips
export const LinkButton = React.forwardRef((props, ref) => (
  <div ref={ref} className='btn-group'>
    <MyLinkButton {...props} />
  </div>
));
LinkButton.displayName = 'LinkButton';

export function BadgeCounter({ children, nb, className, ...props }) {
  return (
    <Badge {...props} className={clsx('position-relative', className)}>
      {children}
      <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark'>{nb}</span>
    </Badge>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { Alert, Badge, ButtonGroup, Card, Col, Container, Form, Row, Spinner, Stack, Table } from 'react-bootstrap';
import ReactJson from 'react-json-view';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { AnalysisButton, CopyButton, DownloadButton, LinkButton, Tooltiped } from '../Components/CommonButtons';
import { CCard, HTTPHeaders, RawPlaylist } from '../Components/CommonCards';
import InputComponent from '../Components/InputComponent';
import { buildQuery, httpGet, showPlayButton, useQueryParams } from '../utils';

const MyContext = React.createContext();

function checkStreamsType(children) {
  const types = { video: false, audio: false };
  for (const si of children.filter((c) => c.name === 'StreamIndex')) {
    if (si.attributes.Type === 'video') {
      types.video = true;
    } else if (si.attributes.Type === 'audio') {
      types.audio = true;
    }
  }
  return types;
}

function Header() {
  const { search } = useLocation();
  const { media, asset } = useQueryParams();

  return (
    <Card border='success'>
      <Card.Header>
        HSS manifest - <b className='text-primary'>{asset}</b>
      </Card.Header>
      <Card.Body>
        <Stack gap='2'>
          <Stack direction='horizontal' gap='1'>
            <InputComponent text='Manifest URL'>
              <Form.Control value={media} readOnly />
            </InputComponent>
            <ButtonGroup>
              <Tooltiped text='play stream'>
                <LinkButton to={`/player${search}`} disabled={!showPlayButton(media)}>
                  <i className='fas fa-play' />
                </LinkButton>
              </Tooltiped>
              <DownloadButton href={media} />
              <CopyButton text={media} size='md' />
            </ButtonGroup>
          </Stack>
        </Stack>
      </Card.Body>
    </Card>
  );
}

// nice name
// just bg light and text dark everywhere
// add props and children if needed...
function MyBadge(props) {
  return <Badge bg='light' text='dark' {...props} />;
}

function elemLayout(child, { media, format, DRMServiceId, licenseVersion, t0, asset }) {
  switch (child.name) {
    case 'SmoothStreamingMedia': {
      const streams = checkStreamsType(child.children);
      return {
        open: true,
        color: 'info',
        header: (
          <>
            <MyBadge>{child.attributes.IsLive?.toLowerCase() === 'true' ? 'Live' : 'VoD'}</MyBadge>
            {Object.entries(streams)
              .filter(([_, v]) => !v)
              .map(([k, _]) => (
                <Badge key={k} bg='danger' text='white'>
                  {k.toUpperCase()} STREAM MISSING
                </Badge>
              ))}
          </>
        ),
      };
    }
    case 'Protection':
      return { color: 'success' };
    case 'StreamIndex':
      streamName = child.attributes.Name;
      return {
        color: 'primary',
        header: (
          <>
            <MyBadge>{child.attributes.Type}</MyBadge>
            <MyBadge>{child.attributes.Name}</MyBadge>
            {child.attributes.Language && <MyBadge>{child.attributes.Language}</MyBadge>}
          </>
        ),
      };

    case 'QualityLevel':
      return {
        color: 'info',
        header: <MyBadge>{child.attributes.Bitrate}</MyBadge>,
        rightHeader: (
          <AnalysisButton
            className='float-end'
            variant='dark'
            size='sm'
            as={Link}
            to={`/manifest/chunks?${buildQuery({
              manifest: media,
              format,
              streamname: streamName,
              bitrate: child.attributes.Bitrate,
              t0,
              DRMServiceId,
              licenseVersion,
              asset,
            })}`}>
            Chunks list
          </AnalysisButton>
        ),
      };
    default:
      return { color: 'light' };
  }
}

function ManifestElement({ child }) {
  const query = useQueryParams();
  const { expand, filter } = useContext(MyContext);
  const { color, header, rightHeader, open } = elemLayout(child, query);

  function walk() {
    return child.children.map((c, i) => <ManifestElement child={c} key={i} />);
  }

  return (
    <>
      {filter.test(child.name) ? (
        <CCard
          open={open || expand}
          title={child.name}
          header={header}
          rightHeader={rightHeader}
          color={color}
          border={color === 'light' ? 'dark' : color}>
          <Stack gap='1'>
            {Object.keys(child.attributes).length > 0 && (
              <Table size='sm' hover>
                <tbody>
                  {Object.entries(child.attributes).map(([k, v]) => (
                    <tr key={k}>
                      <td>
                        <b>{k}</b>
                      </td>
                      <td>{v}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {child.text && <span>{child.text}</span>}
            {child.json && (
              <ReactJson src={child.json} collapsed={1} displayDataTypes={false} theme='monokai' style={{ fontSize: 'medium' }} />
            )}
            {walk()}
          </Stack>
        </CCard>
      ) : (
        walk()
      )}
    </>
  );
}

let streamName;

function Main() {
  streamName = null;
  const { media } = useQueryParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [resp, setResp] = useState(null);
  const [expand, setExpand] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const debounced = useDebouncedCallback((filter) => {
    searchParams.set('filter', filter);
    setSearchParams(searchParams);
  }, 500);

  useEffect(() => {
    httpGet(`/api/hss/manifest?manifest=${encodeURIComponent(media)}`, setResp, setError, () => setLoading(false));
  }, [media]);

  let filter;
  try {
    filter = new RegExp(searchParams.get('filter') ?? '', 'i');
  } catch {
    // impossible regex
    filter = /(?!x)x/;
  }

  return (
    <Container>
      {loading && <Spinner variant='primary' />}
      {error && <Alert variant='danger'>{error}</Alert>}
      {resp && (
        <Stack gap='2'>
          <Header />
          <HTTPHeaders headers={resp.headers} />
          <RawPlaylist playlist={resp.raw} />
          <Row>
            <Col>
              <InputComponent text='Filter' icon='fas fa-filter'>
                <Form.Control
                  defaultValue={searchParams.get('filter') ?? ''}
                  onChange={(evt) => debounced(evt.target.value)}
                  placeholder='regex to filter tags'
                />
              </InputComponent>
            </Col>
            <Col md='auto' className='d-flex align-items-center'>
              <Form.Check type='switch' label='Expand/Collapse all' checked={expand} onChange={() => setExpand(!expand)} />
            </Col>
          </Row>
          <MyContext.Provider value={{ filter, expand }}>
            <ManifestElement child={resp.data.children} />
          </MyContext.Provider>
        </Stack>
      )}
    </Container>
  );
}

export default Main;

import React, { Children, Fragment, useMemo, useState } from 'react';
import { Pagination } from 'react-bootstrap';

import { range } from '../utils';

const DOTS = '...';

export function usePagination(page, lastPage, nbSiblings = 2) {
  return useMemo(() => {
    if (2 * nbSiblings + 1 >= lastPage) {
      return range(1, lastPage + 1);
    }
    const showLeftDots = page - nbSiblings > 1;
    const showRightDots = page + nbSiblings < lastPage;
    const firstIdx = showLeftDots ? page - nbSiblings : 1;
    const lastIdx = showRightDots ? page + nbSiblings : lastPage;
    const pages = range(firstIdx, lastIdx + 1);

    if (showLeftDots && !showRightDots) {
      return [DOTS, ...pages];
    }
    if (!showLeftDots && showRightDots) {
      return [...pages, DOTS];
    }
    return [DOTS, ...pages, DOTS];
  }, [page, lastPage, nbSiblings]);
}

export function Paginate({ onChange, total, page, len, nbSiblings }) {
  const lastPage = Math.ceil(total / len);
  const paginationRange = usePagination(page, lastPage, nbSiblings);
  if (total <= len) {
    return null;
  }
  const showSides = paginationRange.includes(DOTS);
  const isFirstPage = page === 1;
  const isLastPage = page === lastPage;

  return (
    <Pagination className='justify-content-center'>
      {showSides && (
        <>
          <Pagination.First onClick={() => onChange(1)} disabled={isFirstPage} />
          <Pagination.Prev onClick={() => onChange(page - 1)} disabled={isFirstPage} />
        </>
      )}
      {paginationRange.map((n, i) => (
        <Fragment key={i}>
          {n === DOTS ? (
            <Pagination.Ellipsis disabled />
          ) : (
            <Pagination.Item active={page === n} onClick={() => onChange(n)}>
              {n}
            </Pagination.Item>
          )}
        </Fragment>
      ))}
      {showSides && (
        <>
          <Pagination.Next onClick={() => onChange(page + 1)} disabled={isLastPage} />
          <Pagination.Last onClick={() => onChange(lastPage)} disabled={isLastPage} />
        </>
      )}
    </Pagination>
  );
}

// Should be used for regular children (i.e: not Tables because <ul> <li> forbidden inside <tbody>)
// encapsulate your children like this:
// <Paginator len='5'>
//   ...
// </Paginator>
// easier to use
export function Paginator(props) {
  const [page, setPage] = useState(1);

  if (Children.count(props.children) <= props.len) {
    return props.children;
  }

  return (
    <>
      {props.children.slice(props.len * (page - 1), props.len * page)}
      <div className='mt-3'>
        <Paginate len={props.len} onChange={setPage} page={page} total={props.children.length} />
      </div>
    </>
  );
}

export default Paginate;

import React, { useEffect, useState } from 'react';
import { Alert, Card, Col, Container, ListGroup, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { httpGet } from '../utils';

function HomeTools() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tools, setTools] = useState(null);

  useEffect(() => {
    httpGet(
      '/api/tools',
      (body) => setTools(body.menu),
      setError,
      () => setLoading(false)
    );
  }, []);

  function itemProps(e) {
    if (e.href.startsWith('http')) {
      return {
        as: 'a',
        href: e.href,
        target: '_blank',
      };
    } else {
      return { as: Link, to: e.href };
    }
  }

  return (
    <Container fluid>
      {loading && <Spinner variant='primary' />}
      {error && <Alert variant='danger'>{error}</Alert>}
      {tools && (
        <Row xxl='5' lg='4' md='3' sm='2' xs='1' className='g-3 mt-1'>
          {tools.map((t) => (
            <Col key={t.title} className='d-flex'>
              <Card key={t.title} border='secondary' className='flex-fill homecard'>
                <Card.Body>
                  <Card.Title>{t.title}</Card.Title>
                  <hr className='mb-0' />
                  <ListGroup variant='flush'>
                    {t.items.map((e) => (
                      <ListGroup.Item key={e.label} action {...itemProps(e)}>
                        <span className='text-primary'>{e.label}</span>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
}

export default HomeTools;

import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Card, Col, Container, Form, Row, Spinner, Stack } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { LinkButton } from '../Components/CommonButtons';
import InputComponent from '../Components/InputComponent';
import { browser, buildQuery, httpGet } from '../utils';

function showPlayButton2(format) {
  if (browser && browser.name === 'safari' && browser.os.toLowerCase().indexOf('windows') === -1) {
    return format.startsWith('hls');
  }
  return format.startsWith('dash') || format.startsWith('hss');
}

function ZoneConfiguration({ filters, routes, families, zone, label, subdir }) {
  const [family, setFamily] = useState(families[0]);
  const [route, setRoute] = useState(routes[0]);
  const [filter, setFilter] = useState(Object.keys(filters)[0]);

  const queryString = buildQuery({
    config: zone,
    stream: route,
    filter,
    family,
    subdir,
  });

  const disableButton = !showPlayButton2(family);

  return (
    <Card border='secondary' className='flex-fill homecard'>
      <Card.Body>
        <Card.Title>
          [{zone}] {label}
        </Card.Title>
        <hr />
        <Stack gap='3'>
          <InputComponent text='Family' icon='fas fa-tv'>
            <Form.Select onChange={(evt) => setFamily(evt.target.value)}>
              {families.map((f) => (
                <option key={f}>{f}</option>
              ))}
            </Form.Select>
          </InputComponent>
          <InputComponent text='Route' icon='fas fa-signs-post'>
            <Form.Select onChange={(evt) => setRoute(evt.target.value)}>
              {routes.map((r) => (
                <option key={r}>{r}</option>
              ))}
            </Form.Select>
          </InputComponent>
          <InputComponent text='Filter' icon='fas fa-filter'>
            <Form.Select onChange={(evt) => setFilter(evt.target.value)}>
              {Object.entries(filters).map(([id, name]) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Form.Select>
          </InputComponent>
          <Stack direction='horizontal'>
            <LinkButton to={`/monitoring-player?${queryString}`} disabled={disableButton}>
              <i className='fas fa-play' /> Zapping
            </LinkButton>
            <div className='ms-auto'>
              <LinkButton to={`/mosaique?mode=channels&${queryString}`} disabled={disableButton}>
                <i className='fas fa-table-cells' /> Mosaic
              </LinkButton>
            </div>
          </Stack>
        </Stack>
      </Card.Body>
    </Card>
  );
}

function HomeMonitoringPlayers() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [services, setServices] = useState(null);
  const { search } = useLocation();

  useEffect(() => {
    httpGet(`/api/services${search}`, setServices, setError, () => setLoading(false));
  }, [search]);

  return (
    <Container fluid>
      {loading && <Spinner variant='primary' />}
      {error && <Alert variant='danger'>{error}</Alert>}
      {services && (
        <Stack gap='2'>
          {Object.entries(services)
            .filter(([_, service]) => Object.keys(service).length > 0)
            .map(([category, service]) => (
              <Fragment key={category}>
                <h1 className='m-0'>{category}</h1>
                <hr className='mt-0' />
                <Row xxl='5' lg='4' md='3' sm='2' xs='1' className='g-3'>
                  {Object.entries(service)
                    .sort()
                    .map(([zone, infos]) => (
                      <Col key={zone} className='d-flex'>
                        <ZoneConfiguration
                          key={zone}
                          filters={infos.filters}
                          routes={infos.routes}
                          families={infos.families}
                          zone={zone}
                          label={infos.name}
                          subdir={infos.subdir}
                        />
                      </Col>
                    ))}
                </Row>
              </Fragment>
            ))}
        </Stack>
      )}
    </Container>
  );
}

export default HomeMonitoringPlayers;

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, ButtonGroup, Col, Container, Form, Spinner, Stack, Table } from 'react-bootstrap';
import ReactJson from 'react-json-view';
import { Link, useSearchParams } from 'react-router-dom';

import { AnalysisButton, CopyButton, LinkButton, Tooltiped } from '../Components/CommonButtons';
import { CCard, KVCard } from '../Components/CommonCards';
import InputComponent from '../Components/InputComponent';
import ctx from '../context';
import { buildQuery, fastHash, getFormatFromUrl, getServiceIdFromMediaKey, httpGet, showPlayButton } from '../utils';

const provState = {
  ready: { icon: 'fas fa-check', color: 'success' },
  error: { icon: 'fas fa-exclamation', color: 'danger' },
  remove: { icon: 'far fa-trash-alt', color: 'danger' },
  disabled: { icon: 'fas fa-ban', color: 'secondary' },
  pending: { icon: 'fas fa-spinner', color: 'warning' },
};

const playlistType = {
  dash: 'mpd',
  smooth: 'manifest',
  hls: 'm3u8/master',
};

const FAMILY_NONE = '-';

const RmuSortOptions = {
  Family: 'family',
  Target: 'target',
  mediaKey: 'mediaKey',
  contentPath: 'contentPath',
  distribId: 'distribId',
  'Provisioning Status': 'provisioning',
};

const OtherSortOptions = {
  Target: 'target',
  mediaKey: 'mediaKey',
  distribURL: 'distribUrl',
  distribId: 'distribId',
  'Provisioning Status': 'provisioning',
};

const defaultSortRmu = Object.values(RmuSortOptions)[0];
const defaultSortOther = Object.values(OtherSortOptions)[0];
const defaultCrit = 'all';

const criteriaListRmu = ['target', 'family', 'distribId', 'provisioning'];
const criteriaListOther = ['target', 'distribId', 'provisioning'];

function getRGBColorCode(value) {
  return `#${fastHash(value).toString().substring(0, 6)}`;
}

function getBifFileName(files) {
  for (const f of files) {
    if (f.type === 'bif') {
      return `./${f.distribURL.split('/').pop()}`;
    }
  }
}

function getCriteriaValues(criteriaList, list) {
  return Object.fromEntries(criteriaList.map((crit) => [crit, new Set(list.map((item) => item[crit]))]));
}

function buildLocation(title, item, source) {
  const queryString = buildQuery({
    mediaKey: item.mediaKey,
    drmId: item.drmId,
    title,
    contentPath: item.contentPath,
    bif: getBifFileName(item.files),
    version: item.version,
    type: source === 'hapi' ? '!VOD2Live' : `VOD2Live ${item.drmType}`,
  });
  return `/routes/vod/${item.region}/${item.family}?${queryString}`;
}

function buildPlayerLink(title, item, drmserviceid) {
  const queryString = buildQuery({
    media: item.distribUrl,
    format: getFormatFromUrl(item.distribUrl),
    asset: title,
    drmId: item.drmId,
    bif: getBifFileName(item.files),
    version: item.version,
    licenseVersion: 2,
    DRMServiceId: getServiceIdFromMediaKey(drmserviceid, item.mediaKey),
  });
  return `/player?${queryString}`;
}

function buildAnalysisLink(title, item, drmserviceid) {
  const format = getFormatFromUrl(item.distribUrl);
  const queryString = buildQuery({
    media: item.distribUrl,
    format,
    asset: title,
    drmId: item.drmId,
    bif: getBifFileName(item.files),
    version: item.version,
    licenseVersion: 2,
    DRMServiceId: getServiceIdFromMediaKey(drmserviceid, item.mediaKey),
  });
  return `/${playlistType[format]}?${queryString}`;
}

function getDeviceFromTarget(hapiTargetList, mediaTarget) {
  for (const [target, device] of Object.entries(hapiTargetList)) {
    if (mediaTarget.indexOf(target) !== -1) {
      return device;
    }
  }
  return mediaTarget;
}

function RMUTargets({ list, criteriaValues, title, hapiTargetList, source }) {
  const [sort, setSort] = useState(defaultSortRmu);
  const [filters, setFilters] = useState(Object.fromEntries(criteriaListRmu.map((crit) => [crit, defaultCrit])));

  function matchFilters(item) {
    return Object.entries(filters).every(([crit, val]) => val === 'all' || item[crit] === val);
  }

  const finalList = list.filter(matchFilters).sort((a, b) => (a[sort] < b[sort] ? -1 : a[sort] > b[sort] ? 1 : 0));

  return (
    <CCard
      title={
        <span>
          Targets <span className='text-success'>with</span> RouteMeUp URL
        </span>
      }
      border='dark'
      open>
      <Stack gap='3'>
        <Stack direction='horizontal' gap='2'>
          <Col md='2'>
            <InputComponent icon='fas fa-sort' size='sm'>
              <Form.Select value={sort} onChange={(evt) => setSort(evt.target.value)}>
                {Object.entries(RmuSortOptions).map(([name, k]) => (
                  <option key={name} value={k}>
                    {name}
                  </option>
                ))}
              </Form.Select>
            </InputComponent>
          </Col>
          {criteriaListRmu.map((crit) => (
            <InputComponent key={crit} icon='fas fa-filter' text={crit} size='sm'>
              <Form.Select value={filters[crit]} onChange={(evt) => setFilters((prev) => ({ ...prev, [crit]: evt.target.value }))}>
                <option value='all'>ALL VALUES</option>
                {[...criteriaValues[crit]].map((v) => (
                  <option key={v}>{v}</option>
                ))}
              </Form.Select>
            </InputComponent>
          ))}
        </Stack>
        <Table size='sm' hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Routes</th>
              <th>Family</th>
              <th>Target</th>
              <th>mediaKey</th>
              <th>contentPath</th>
              <th>Version</th>
              <th>distribId</th>
              <th>Provisioning</th>
              <th>rmuMode</th>
            </tr>
          </thead>
          <tbody>
            {finalList.map((item, i) => (
              <tr key={item.mediaKey}>
                <td>{i}</td>
                <td>
                  <Tooltiped text='analysis'>
                    <LinkButton
                      style={{ padding: 0 }}
                      to={buildLocation(title, item, source)}
                      variant='link'
                      disabled={item.family === FAMILY_NONE}>
                      <i className='fas fa-hdd' />
                    </LinkButton>
                  </Tooltiped>
                </td>
                <td style={{ color: item.family === FAMILY_NONE ? null : getRGBColorCode(item.family) }}>{item.family}</td>
                <Tooltiped text={getDeviceFromTarget(hapiTargetList, item.target)}>
                  <td>{item.target}</td>
                </Tooltiped>
                <td>{item.mediaKey.split('|').at(-1)}</td>
                <td>{item.contentPath}</td>
                <td>{item.version}</td>
                <td style={{ color: getRGBColorCode(item.distribId) }}>{item.distribId}</td>
                <td className='text-center'>
                  <Tooltiped text={item.provisioning}>
                    <i className={`${provState[item.provisioning].icon} text-${provState[item.provisioning].color}`} />
                  </Tooltiped>
                </td>
                <td>{item.rmuMode}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ReactJson
          src={finalList}
          collapsed={1}
          displayDataTypes={false}
          theme='monokai'
          style={{ fontSize: 'medium' }}
          name='media_info'
        />
      </Stack>
    </CCard>
  );
}

function OtherTargets({ list, criteriaValues, title, hapiTargetList }) {
  const { DRMServiceId } = useContext(ctx);
  const [sort, setSort] = useState(defaultSortOther);
  const [filters, setFilters] = useState(Object.fromEntries(criteriaListOther.map((crit) => [crit, defaultCrit])));

  function matchFilters(item) {
    return Object.entries(filters).every(([crit, val]) => val === 'all' || item[crit] === val);
  }

  const finalList = list.filter(matchFilters).sort((a, b) => (a[sort] < b[sort] ? -1 : a[sort] > b[sort] ? 1 : 0));
  return (
    <CCard
      title={
        <span>
          Targets <span className='text-danger'>without</span> RouteMeUp URL
        </span>
      }
      border='dark'
      open>
      <Stack gap='3'>
        <Stack direction='horizontal' gap='2'>
          <Col md='2'>
            <InputComponent icon='fas fa-sort' size='sm'>
              <Form.Select value={sort} onChange={(evt) => setSort(evt.target.value)}>
                {Object.entries(OtherSortOptions).map(([name, k]) => (
                  <option key={name} value={k}>
                    {name}
                  </option>
                ))}
              </Form.Select>
            </InputComponent>
          </Col>
          {criteriaListOther.map((crit) => (
            <InputComponent key={crit} icon='fas fa-filter' text={crit} size='sm'>
              <Form.Select value={filters[crit]} onChange={(evt) => setFilters((prev) => ({ ...prev, [crit]: evt.target.value }))}>
                <option value='all'>ALL VALUES</option>
                {[...criteriaValues[crit]].map((v) => (
                  <option key={v}>{v}</option>
                ))}
              </Form.Select>
            </InputComponent>
          ))}
        </Stack>
        <Table size='sm' hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Action</th>
              <th>Target</th>
              <th>mediaKey</th>
              <th>distribURL</th>
              <th>Version</th>
              <th>distribId</th>
              <th>Provisioning</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, i) => (
              <tr key={item.mediaKey}>
                <td>{i}</td>
                <td>
                  <ButtonGroup size='sm'>
                    <Tooltiped text='play stream'>
                      <LinkButton to={buildPlayerLink(title, item, DRMServiceId)} size='sm' disabled={!showPlayButton(item.distribUrl)}>
                        <i className='fas fa-play' />
                      </LinkButton>
                    </Tooltiped>
                    <AnalysisButton as={Link} to={buildAnalysisLink(title, item, DRMServiceId)} />
                    <CopyButton text={item.distribUrl} />
                  </ButtonGroup>
                </td>
                <Tooltiped text={getDeviceFromTarget(hapiTargetList, item.target)}>
                  <td>{item.target}</td>
                </Tooltiped>
                <td>{item.mediaKey.split('|').at(-1)}</td>
                <td>{item.distribUrl}</td>
                <td>{item.version}</td>
                <td style={{ color: getRGBColorCode(item.distribId) }}>{item.distribId}</td>
                <td className='text-center'>
                  <Tooltiped text={item.provisioning}>
                    <i className={`${provState[item.provisioning].icon} text-${provState[item.provisioning].color}`} />
                  </Tooltiped>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ReactJson
          src={finalList}
          collapsed={1}
          displayDataTypes={false}
          theme='monokai'
          style={{ fontSize: 'medium' }}
          name='media_info'
        />
      </Stack>
    </CCard>
  );
}

function VODTools() {
  const [searchParams, setSearchParams] = useSearchParams();
  const fetchNow = useRef(searchParams.has('idKey'));
  const [idKey, setIdKey] = useState(searchParams.get('idKey') || '');
  const [source, setSource] = useState(searchParams.get('source') || 'hapi');
  const [hapiTargetList, setHapiTargetList] = useState(null);
  const [loadingProv, setLoadingProv] = useState(false);
  const [errorProv, setErrorProv] = useState(null);
  const [prov, setProv] = useState(null);
  const [criteriaValues, setCriteriaValues] = useState(null);

  const getProvisioning = useCallback(() => {
    setLoadingProv(true);
    setErrorProv(null);
    setProv(null);
    httpGet(
      `/api/vod/provisioning?idKey=${idKey}&source=${source}`,
      (body) => {
        setProv(body.data);
        setHapiTargetList(body.hapi_target_list);
        setCriteriaValues({
          medias: getCriteriaValues(criteriaListRmu, body.data.rmuDevices),
          others: getCriteriaValues(criteriaListOther, body.data.otherDevices),
        });
      },
      setErrorProv,
      () => setLoadingProv(false)
    );
  }, [idKey, source]);

  useEffect(() => {
    if (fetchNow.current) {
      // do it only once, to prevent re-run http request when setSearchParams called
      fetchNow.current = false;
      getProvisioning();
    }
  }, [getProvisioning]);

  useEffect(() => {
    setSearchParams((prev) => {
      prev.set('idKey', idKey);
      prev.set('source', source);
      return prev;
    });
    // setSearchParams is not stable because the team behind react-router-dom doesn't seem to know about ref
  }, [idKey, source, setSearchParams]);
  return (
    <Container style={{ maxWidth: '95%' }}>
      <h3>VOD - CDN Tools</h3>
      <hr />
      <Stack gap='3'>
        <Stack direction='horizontal' gap='2'>
          <Col md='2'>
            <InputComponent text='Source' icon='fas fa-server'>
              <Form.Select onChange={(evt) => setSource(evt.target.value)} value={source}>
                <option value='hapi'>HAPI</option>
                <option value='plm_ingest'>PLM Ingest</option>
              </Form.Select>
            </InputComponent>
          </Col>
          <Col md='4'>
            <InputComponent text='idKey' icon='fas fa-hashtag'>
              <Tooltiped text='canalplay_XXXXX should be replaced by mvs_movie_XXXXX'>
                <Form.Control
                  autoFocus
                  onKeyDown={({ key }) => (key === 'Enter' ? getProvisioning() : null)}
                  onChange={(evt) => setIdKey(evt.target.value)}
                  value={idKey}
                />
              </Tooltiped>
            </InputComponent>
          </Col>
          <Button variant='outline-primary' onClick={getProvisioning}>
            <i className='fas fa-search' />
          </Button>
          {loadingProv && <Spinner variant='primary' />}
        </Stack>
        {errorProv && <Alert variant='danger'>{errorProv}</Alert>}
        {prov && (
          <>
            <Col md='8'>
              <KVCard title={prov.infos.Title} obj={prov.infos} open border='dark' />
            </Col>
            {prov.rmuDevices.length > 0 && (
              <RMUTargets
                hapiTargetList={hapiTargetList}
                list={prov.rmuDevices}
                rmuDevices
                criteriaValues={criteriaValues.medias}
                title={prov.infos.Title}
                source={source}
              />
            )}
            {prov.otherDevices.length > 0 && (
              <OtherTargets
                hapiTargetList={hapiTargetList}
                list={prov.otherDevices}
                criteriaValues={criteriaValues.others}
                title={prov.infos.Title}
              />
            )}
          </>
        )}
      </Stack>
    </Container>
  );
}

export default VODTools;

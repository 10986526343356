import React from 'react';

import CCard from './CommonCards';

const maxDeltaTime = (type) => (type === 'ad-insert' ? 1800 : 60);

function CheckChunkTimeComponent({ requestTime, lastChunkTime, streamType }) {
  const delta = Math.round((requestTime - lastChunkTime) / 1e3);
  if (isNaN(delta)) {
    console.error(`delta is NaN (requestTime: ${requestTime}; lastChunkTime: ${lastChunkTime}`);
    return null;
  }
  const isBad = delta >= maxDeltaTime(streamType);
  const color = isBad ? 'danger' : 'success';
  const Title = () => (
    <span className={`text-${color}`}>
      <i className={isBad ? 'fas fa-exclamation-triangle me-1' : 'fas fa-circle-check me-1'} />
      Time interval between playlist request and last chunk: <b>{delta}s</b>
    </span>
  );
  return (
    <CCard title={<Title />} border={color} open={isBad}>
      <span>
        <b>Request time:</b> {new Date(requestTime).toLocaleString()}
      </span>
      <br />
      <span>
        <b>Last chunk time:</b> {new Date(lastChunkTime).toLocaleString()}
      </span>
    </CCard>
  );
}

export default CheckChunkTimeComponent;

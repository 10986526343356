import clsx from 'clsx';
import React from 'react';
import { Button, Offcanvas } from 'react-bootstrap';

function PlayerLogsCanvas({ showLogs, onHide, player, logs, ...props }) {
  if (!player) {
    return null;
  }

  const hasError = logs.some(({ style }) => style === 'text-danger');

  return (
    <>
      <Button variant={hasError ? 'danger fader' : 'outline-primary'} {...props}>
        <i className={clsx('fas', hasError ? 'fa-exclamation-triangle' : 'fa-message')} /> Show logs panel {hasError && '(found errors)'}
      </Button>
      <Offcanvas placement='bottom' show={showLogs} onHide={onHide} backdrop style={{ height: '45%' }}>
        <Offcanvas.Header closeButton className='m-1 p-1 border-bottom border-secondary'>
          <Offcanvas.Title className='ms-4'>
            RxPlayer v{player.version} - State
            <b className='text-primary'> {player.state}</b>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul style={{ listStyle: 'none' }}>
            {logs.map((l, i) => (
              <li className={l.style} key={i}>
                <i className='fas fa-angle-right' /> {l.text}
              </li>
            ))}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default PlayerLogsCanvas;

import clsx from 'clsx';
import React from 'react';
import { InputGroup } from 'react-bootstrap';

function InputComponent({ icon, text, children, ...props }) {
  return (
    <InputGroup {...props}>
      <InputGroup.Text className='bg-light text-primary'>
        {icon && <i className={clsx(icon, text && 'me-1')} />}
        {text}
      </InputGroup.Text>
      {children}
    </InputGroup>
  );
}

export default InputComponent;

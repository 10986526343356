import React from 'react';
import { ListGroup } from 'react-bootstrap';

import CCard from './CommonCards';

const stateIcon = {
  loading: 'spinner-border spinner-border-sm text-primary',
  ok: 'fas fa-check-circle text-success',
  ko: 'fas fa-times-circle text-danger',
  warning: 'fas fa-exclamation-circle text-warning',
};

function StatusListComponent({ list }) {
  const entries = Object.entries(list);

  if (entries.length === 0) {
    return null;
  }

  return (
    <CCard border='dark' title={<b className='text-primary'>Status</b>} open body={false}>
      <ListGroup size='sm' variant='flush'>
        {entries.map(([k, v]) => (
          <ListGroup.Item key={k}>
            {v.label}
            <i className={`${stateIcon[v.state]} float-end`} />
          </ListGroup.Item>
        ))}
      </ListGroup>
    </CCard>
  );
}

export default StatusListComponent;

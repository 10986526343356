import React, { useContext, useState } from 'react';
import { Col, Container, Form, InputGroup, Row, Stack } from 'react-bootstrap';

import { LinkButton } from '../Components/CommonButtons';
import InputComponent from '../Components/InputComponent';
import ctx from '../context';
import { analysisRootUrl, buildQuery, drmNames, url2Format } from '../utils';

const playerTypes = {
  Auto: 'auto',
  HSS: 'smooth',
  Dash: 'dash',
  HLS: 'hls',
};

function HomePlayers() {
  const { DRMServiceId, licenseServerUri } = useContext(ctx);
  const [type, setType] = useState(playerTypes.Auto);
  const [playlist, setPlaylist] = useState('');
  const [licenseServerUriInput, setLicenseServerUriInput] = useState('');
  const [drmId, setDrmId] = useState('');
  const [drmServiceId, setDrmServiceId] = useState(Object.values(DRMServiceId)[0]);
  const [hlsType, setHlsType] = useState('hls');

  const format = type === playerTypes.Auto ? url2Format(playlist) : type;

  const queryString = buildQuery({
    drmId: drmId.length === 0 ? null : drmId,
    format,
    media: playlist,
    license: licenseServerUriInput,
    DRMServiceId: drmServiceId,
    m3u8: hlsType === 'hlsMedia' ? playlist : null,
  });

  const disabled = playlist === '' || !format;

  const analysisUrl = format === 'hls' ? analysisRootUrl[hlsType] : analysisRootUrl[format];

  return (
    <Container style={{ maxWidth: '70%' }}>
      <h3>Player configuration</h3>
      <hr />
      <Stack gap='3'>
        <Col md='5'>
          <InputComponent text='Player type'>
            <Form.Select onChange={(evt) => setType(evt.target.value)}>
              {Object.entries(playerTypes).map(([label, value]) => (
                <option key={label} value={value}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </InputComponent>
        </Col>
        <Col md='10'>
          <InputComponent text='Playlist URL'>
            <Form.Control autoFocus placeholder='playlist URL' onChange={(evt) => setPlaylist(evt.target.value)} />
            <InputGroup.Text className='text-primary'>{format ?? 'unknown'}</InputGroup.Text>
          </InputComponent>
        </Col>
        {format === 'hls' && (
          <Form>
            <Form.Check inline checked={hlsType === 'hls'} onChange={() => setHlsType('hls')} type='radio' label='MasterPlaylist' />
            <Form.Check
              inline
              checked={hlsType === 'hlsMedia'}
              onChange={() => setHlsType('hlsMedia')}
              type='radio'
              label='MediaPlaylist'
            />
          </Form>
        )}
        <Col md='10'>
          <InputComponent text='License WS URI'>
            <Form.Control
              list='licenseServerUris'
              placeholder='license server URI'
              onChange={(evt) => setLicenseServerUriInput(evt.target.value)}
            />
            <datalist id='licenseServerUris'>
              {Array.from(licenseServerUri.values()).flatMap((uri) =>
                drmNames.map((suffix) => <option key={`${uri}/${suffix}`} value={`${uri}/${suffix}`} />)
              )}
            </datalist>
          </InputComponent>
        </Col>
        <Row>
          <Col md='5'>
            <InputComponent text='DRM Service ID'>
              <Form.Select onChange={(evt) => setDrmServiceId(evt.target.value)}>
                {Object.entries(DRMServiceId).map(([name, id]) => (
                  <option key={name} value={id}>
                    {`${name} (service ID: ${id})`}
                  </option>
                ))}
              </Form.Select>
            </InputComponent>
          </Col>
          <Col md='5'>
            <InputComponent text='drmId'>
              <Form.Control placeholder='drmId' onChange={(evt) => setDrmId(evt.target.value)} />
            </InputComponent>
          </Col>
        </Row>
        <Stack direction='horizontal' gap='2'>
          <LinkButton to={`/player?${queryString}`} disabled={disabled}>
            <i className='fas fa-play' /> Play
          </LinkButton>
          <LinkButton to={`${analysisUrl}?${queryString}`} disabled={disabled}>
            <i className='fas fa-circle-info' /> Analysis
          </LinkButton>
        </Stack>
      </Stack>
    </Container>
  );
}

export default HomePlayers;

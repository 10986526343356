import React, { useEffect, useState } from 'react';
import { Alert, Badge, ButtonGroup, Card, Container, Form, Spinner, Stack, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import CheckChunkTimeComponent from '../Components/CheckChunkTimeComponent';
import { AnalysisButton, CopyButton, DownloadButton, LinkButton, Tooltiped } from '../Components/CommonButtons';
import { HTTPHeaders } from '../Components/CommonCards';
import InputComponent from '../Components/InputComponent';
import Paginate from '../Components/Paginate';
import { buildQuery, httpGet, S2TimeString, showPlayButton, useQueryParams } from '../utils';

const ITEMS_PER_PAGE = 155;

function Header() {
  const { manifest, format, t0, DRMServiceId, licenseVersion, asset } = useQueryParams();
  const linksQuery = buildQuery({
    media: manifest,
    format,
    t0,
    DRMServiceId,
    licenseVersion,
    asset,
  });

  return (
    <Card border='success'>
      <Card.Header>
        HSS segments - <b className='text-primary'>{asset}</b>
      </Card.Header>
      <Card.Body>
        <Stack direction='horizontal' gap='1'>
          <InputComponent text='Manifest URL'>
            <Form.Control value={manifest} readOnly />
          </InputComponent>
          <ButtonGroup>
            <Tooltiped text='play stream'>
              <LinkButton disabled={!showPlayButton(manifest)} to={`/player?${linksQuery}`}>
                <i className='fas fa-play' />
              </LinkButton>
            </Tooltiped>
            <AnalysisButton as={Link} to={`/manifest?${linksQuery}`} />
            <DownloadButton href={manifest} />
            <CopyButton text={manifest} size='md' />
          </ButtonGroup>
        </Stack>
      </Card.Body>
    </Card>
  );
}

function ChunksList({ chunks, isLive, timescale }) {
  const { streamname, bitrate } = useQueryParams();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');

  const start = (page - 1) * ITEMS_PER_PAGE;
  const end = start + ITEMS_PER_PAGE;
  const filteredChunks = chunks.filter((c) => c.url.indexOf(filter) !== -1);

  useEffect(() => {
    setPage(1);
  }, [filteredChunks.length]);

  return (
    <Card border='primary'>
      <Card.Header>
        <Stack direction='horizontal' gap='2'>
          Chunks list
          <Badge>{streamname}</Badge>
          <Badge>{bitrate / 1e3} kbps</Badge>
          <Badge className='ms-auto'>{filteredChunks.length}</Badge>
        </Stack>
      </Card.Header>
      <Card.Body>
        <Stack gap='3'>
          <InputComponent icon='fas fa-search'>
            <Form.Control placeholder='search...' onChange={(evt) => setFilter(evt.target.value)} />
          </InputComponent>
          {filteredChunks.length === 0 ? (
            <Alert variant='warning'>No segment found</Alert>
          ) : (
            <Table size='sm' hover>
              <thead>
                <tr>
                  <th>Actions</th>
                  <th>Timestamp</th>
                  <th>{isLive ? 'Date' : 'Position'}</th>
                  <th>Duration (s)</th>
                </tr>
              </thead>
              <tbody>
                {filteredChunks.slice(start, end).map((c) => (
                  <tr key={c.url}>
                    <td>
                      <ButtonGroup size='sm'>
                        <DownloadButton href={c.url} />
                        <CopyButton text={c.url} />
                      </ButtonGroup>
                    </td>
                    <td>{c.time}</td>
                    <td>{isLive ? new Date(c.timestamp).toLocaleString() : S2TimeString(c.time / 1e7)}</td>
                    <td>{c.attributes.d / timescale}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          <Paginate len={ITEMS_PER_PAGE} total={filteredChunks.length} page={page} onChange={setPage} />
        </Stack>
      </Card.Body>
    </Card>
  );
}

function Main() {
  const { manifest, streamname, bitrate, t0 } = useQueryParams();
  const [requestTime, setRequestTime] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [resp, setResp] = useState(null);

  const query = buildQuery({ manifest, streamname, bitrate, t0 });

  useEffect(() => {
    setRequestTime(Date.now());
    httpGet(`/api/hss/stream?${query}`, setResp, setError, () => setLoading(false));
  }, [query]);

  return (
    <Container>
      <Stack gap='2'>
        <Header />
        {loading && <Spinner variant='primary' />}
        {error && <Alert variant='danger'>{error}</Alert>}
        {resp && (
          <>
            <HTTPHeaders headers={resp.headers} />
            {resp.data.chunks.length === 0 ? (
              <Alert variant='warning'>Empty chunk list</Alert>
            ) : (
              <>
                {resp.data.isLive && (
                  <CheckChunkTimeComponent
                    requestTime={requestTime}
                    lastChunkTime={new Date(resp.data.chunks.at(-1).timestamp).getTime()}
                    streamType={streamname}
                  />
                )}
                <ChunksList chunks={resp.data.chunks} isLive={resp.data.isLive} timescale={resp.data.timescale} />
              </>
            )}
          </>
        )}
      </Stack>
    </Container>
  );
}
export default Main;

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './index.css';
import 'normalize.css';

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { unregister } from './registerServiceWorker';

createRoot(document.getElementById('root')).render(<App />);
unregister();

import React, { useEffect, useState } from 'react';

import CCard from './CommonCards';

function Main({ player }) {
  const [bufferGap, setBufferGap] = useState(() => Number(player.getCurrentBufferGap().toFixed(2)));
  const [min, setMin] = useState(bufferGap);
  const [max, setMax] = useState(bufferGap);

  useEffect(() => {
    const timer = setInterval(() => {
      const cbg = Number(player.getCurrentBufferGap().toFixed(2));
      setBufferGap(cbg);
      setMin((prev) => Math.min(cbg, prev));
      setMax((prev) => Math.max(cbg, prev));
    }, 1000);
    return () => clearInterval(timer);
  }, [player]);

  return (
    <CCard open border='dark' title={<b className='text-primary'>Video Buffer Gap</b>}>
      <ul>
        <li>Current: {bufferGap}</li>
        <li>Minimum: {min}</li>
        <li>Maximum: {max}</li>
      </ul>
    </CCard>
  );
}

export default Main;

import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Card, Col, Container, ListGroup, Row, Spinner, Stack } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

import { httpGet } from '../utils';

function HomeServices() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [services, setServices] = useState(null);
  const { search } = useLocation();

  useEffect(() => {
    httpGet(`/api/services${search}`, setServices, setError, () => setLoading(false));
  }, [search]);

  return (
    <Container fluid>
      {loading && <Spinner variant='primary' />}
      {error && <Alert variant='danger'>{error}</Alert>}
      {services && (
        <Stack gap='2'>
          {Object.entries(services)
            .filter(([_, service]) => Object.keys(service).length > 0)
            .map(([category, service]) => (
              <Fragment key={category}>
                <h1 className='m-0'>{category}</h1>
                <hr className='mt-0' />
                <Row xxl='5' lg='4' md='3' sm='2' xs='1' className='g-3'>
                  {Object.entries(service)
                    .sort()
                    .map(([zone, infos]) => (
                      <Col key={zone} className='d-flex'>
                        <Card border='secondary' className='flex-fill homecard'>
                          <Card.Body>
                            <Card.Title>
                              [{zone}] {infos.name}
                            </Card.Title>
                            <hr className='mb-0' />
                            <ListGroup variant='flush'>
                              {infos.families.map((f) => (
                                <ListGroup.Item key={f} action as={Link} to={`/routes/${infos.subdir}/${zone}/${f}`}>
                                  <span className='text-primary'>{f}</span>
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                </Row>
              </Fragment>
            ))}
        </Stack>
      )}
    </Container>
  );
}

export default HomeServices;

import React, { Component, useEffect, useState } from 'react';
import { Badge, Button, ButtonGroup, Card, Col, Form, Image, ListGroup, Ratio, Row, Stack } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import { EMBEDDED_DASH_WASM } from 'rx-player/experimental/features/embeds';
import {
  DASH,
  DASH_WASM,
  DEBUG_ELEMENT,
  DIRECTFILE,
  EME,
  HTML_SAMI_PARSER,
  HTML_SRT_PARSER,
  HTML_TEXT_BUFFER,
  HTML_TTML_PARSER,
  HTML_VTT_PARSER,
  NATIVE_SAMI_PARSER,
  NATIVE_SRT_PARSER,
  NATIVE_TEXT_BUFFER,
  NATIVE_TTML_PARSER,
  NATIVE_VTT_PARSER,
  SMOOTH,
} from 'rx-player/features';
import RxPlayer from 'rx-player/minimal';
import { parseBifThumbnails } from 'rx-player/tools';
import TextTrackRenderer, { SAMI_PARSER, SRT_PARSER, TTML_PARSER, VTT_PARSER } from 'rx-player/tools/TextTrackRenderer';

import BufferGapComponent from '../Components/BufferGapComponent';
import { AnalysisButton, CopyButton, DownloadButton, Tooltiped, TooltipedButton } from '../Components/CommonButtons';
import { CCard, KVCard } from '../Components/CommonCards';
import InputComponent from '../Components/InputComponent';
import PeriodComponent from '../Components/PeriodComponent';
import PlayerLogsCanvas from '../Components/PlayerLogsCanvas';
import StatusListComponent from '../Components/StatusListComponent';
import UrlComponent from '../Components/UrlComponent';
import ctx from '../context';
import {
  analysisRootUrl,
  arrayToString,
  drmSystemInfo,
  dtf,
  getCdnName,
  manifestLoader,
  S2TimeString,
  ToggleFullScreen,
  VIDEO_ELEMENT_ID,
} from '../utils';

DASH_WASM.initialize({ wasmUrl: EMBEDDED_DASH_WASM })
  .then(() => console.log('Dash WASM MPD parser initialized'))
  .catch((err) => console.warn('Could not initialize Dash WASM MPD parser', err));

RxPlayer.addFeatures([
  DASH,
  DASH_WASM,
  DEBUG_ELEMENT,
  DIRECTFILE,
  EME,
  HTML_SAMI_PARSER,
  HTML_SRT_PARSER,
  HTML_TEXT_BUFFER,
  HTML_TTML_PARSER,
  HTML_VTT_PARSER,
  NATIVE_SAMI_PARSER,
  NATIVE_SRT_PARSER,
  NATIVE_TEXT_BUFFER,
  NATIVE_TTML_PARSER,
  NATIVE_VTT_PARSER,
  SMOOTH,
]);
// Add the needed parsers to the TextTrackRenderer
TextTrackRenderer.addParsers([TTML_PARSER, VTT_PARSER, SRT_PARSER, SAMI_PARSER]);

// seconds
const PERIOD_TIME_OFFSET = 2;
//vod subtitles format
const vodSubtitlesFormat = {
  vtt: 'vtt',
  smi: 'sami',
  ttml: 'ttml',
};

const MAX_LOG_ITEM = 100;
const DEFAULT_AUDIO_TRACK = 'fra';
const THUMBNAIL_WIDTH = 240; //240px

//PLaying CheckBox configuration
const playerModeCheckBoxList = {
  aggressiveMode: {
    label: 'Aggressive mode',
    popup: 'Segments will be requested without being absolutely sure they had time to be generated',
  },
  lowLatencyMode: {
    label: 'Low latency',
    popup: 'low-latency streaming for DASH contents (using chunked CMAF containers and chunk transfer encoding requests)',
  },
};
//Default player modevalues for checkBox
const playerModeCheckedBoxList = {
  aggressiveMode: false,
  lowLatencyMode: false,
};

//debug option
const debugCheckBoxList = {
  emsg: {
    label: 'emsg',
    popup: 'Display Event Message in console',
  },
  debugPanel: {
    label: (
      <>
        <span>Debug panel </span>(
        <a
          href='https://developers.canal-plus.com/rx-player/doc/api/Miscellaneous/Debug_Element.html#displayed_information'
          target='_blank'
          rel='noreferrer'>
          doc
        </a>
        )
      </>
    ),
    popup: 'Display the debug panel on top of video',
  },
};

const debugCheckedBoxList = {
  emsg: false,
  debugPanel: false,
};

const fallbackModes = {
  onKeyInternalError: {
    label: 'Internal error',
    values: ['error', 'continue', 'fallback', 'close-session'],
    defaultValue: 'fallback',
  },
  onKeyOutputRestricted: {
    label: 'Output restricted',
    values: ['error', 'continue', 'fallback'],
    defaultValue: 'fallback',
  },
  onKeyExpiration: {
    label: 'Key expiration',
    values: ['error', 'continue', 'fallback', 'close-session'],
    defaultValue: 'error',
  },
};

const fallbackOnLastTry = {
  label: 'Quality fallback',
  values: [true, false],
  defaultValue: true,
};

const keySystems = {
  playreadyHw: {
    label: 'PlayReady hardware',
    value: true,
  },
  playready: {
    label: 'Playready',
    value: true,
  },
  widevine: {
    label: 'Widevine',
    value: true,
  },
};

function setPlayerSeek(player, seekValue) {
  const pos = player.getPosition() + seekValue;
  const min = player.getMinimumPosition();
  const max = player.isLive() ? player.getLivePosition() : player.getMaximumPosition();

  player.seekTo({
    position: pos < min ? min : pos > max ? max : pos,
  });
}

function setPlayerSeekTo(player, seekTo) {
  const pos = seekTo;
  const min = player.getMinimumPosition();
  const max = player.isLive() ? player.getLivePosition() : player.getMaximumPosition();

  player.seekTo({
    position: pos < min ? min : pos > max ? max : pos,
  });
}

function CurrentTimeComponent({ player }) {
  const [time, setTime] = useState(() => dtf.format(player.getWallClockTime() * 1000));

  useEffect(() => {
    const timer = setInterval(() => setTime(dtf.format(player.getWallClockTime() * 1000)), 1000);
    return () => clearInterval(timer);
  }, [player]);

  return (
    <InputComponent text='Current time'>
      <Form.Control readOnly value={time} />
    </InputComponent>
  );
}

function RxPlayerLogLevelComponent({ logLevel }) {
  const [searchParams, setSearchParams] = useSearchParams();

  function onChange(evt) {
    const logLevel = evt.target.value;
    searchParams.set('logLevel', logLevel);
    setSearchParams(searchParams);
    RxPlayer.LogLevel = logLevel;
  }

  return (
    <Form.Group>
      <Form.Label className='fw-bold'>RxPlayer Log level</Form.Label>
      <Form.Select onChange={onChange} size='sm' defaultValue={logLevel}>
        <option>NONE</option>
        <option>ERROR</option>
        <option>WARNING</option>
        <option>INFO</option>
        <option>DEBUG</option>
      </Form.Select>
    </Form.Group>
  );
}

function AdditionalPlayerOptions({ isHls }) {
  const [searchParams] = useSearchParams();

  function handleFallbackMode(evt, k) {
    searchParams.set(k, evt.target.value);
    window.location.search = searchParams.toString();
  }

  function handleKeySystems(evt, k) {
    searchParams.set(k, evt.target.checked);
    window.location.search = searchParams.toString();
  }

  return (
    <CCard border='dark' title={<b className='text-primary'>Additional options</b>} open>
      <Stack gap='3'>
        <Form.Group>
          <Form.Label className='fw-bold'>Fallback mode</Form.Label>
          <Stack gap='1'>
            {Object.entries(fallbackModes).map(([k, { label, values, defaultValue }]) => (
              <InputComponent key={k} text={label}>
                <Form.Select onChange={(evt) => handleFallbackMode(evt, k)} defaultValue={searchParams.get(k) ?? defaultValue}>
                  {values.map((v) => (
                    <option key={v}>{v}</option>
                  ))}
                </Form.Select>
              </InputComponent>
            ))}
            <InputComponent text={fallbackOnLastTry.label}>
              <Form.Select
                onChange={(evt) => handleFallbackMode(evt, 'fallbackOnLastTry')}
                defaultValue={searchParams.get('fallbackOnLastTry') ?? fallbackOnLastTry.defaultValue}>
                {fallbackOnLastTry.values.map((v) => (
                  <option key={v}>{v.toString()}</option>
                ))}
              </Form.Select>
            </InputComponent>
          </Stack>
        </Form.Group>
        {!isHls && (
          <Form.Group>
            <Form.Label className='fw-bold'>Key systems</Form.Label>
            {Object.entries(keySystems).map(([k, { label, value }]) => (
              <Form.Check
                key={k}
                label={label}
                name={k}
                checked={searchParams.has(k) ? searchParams.get(k) === value.toString() : true}
                onChange={(evt) => handleKeySystems(evt, k)}
              />
            ))}
          </Form.Group>
        )}
      </Stack>
    </CCard>
  );
}

export class WebTvToolsPlayer extends Component {
  static contextType = ctx;
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      media: '',
      audioList: null,
      representations: [],
      subtitleList: null,
      playerState: 'UNKNOW',
      liveOffset: '00:00:00',
      seekValue: 100,
      currentRepresentation: null,
      isFullScreen: false,
      logs: [],
      cdn: '',
      checkedBoxList: playerModeCheckedBoxList,
      debugCheckedBoxList,
      isLive: false,
      thumbnail: { display: false, index: 0, offsetX: '0px' },
      showPanel: { conf: false, drm: false },
      showLogs: false,
      downloadsState: {},
      counters: {},
      periods: [],
      availabilityStartTime: 0,
      currentPeriodIndex: -1,
      pictos: {},
      bitrateMode: 'Auto',
      isMute: false,
    };
    this.player = null;
    this.getLicenseCounter = 1;
    this.queryParams = Object.fromEntries(new URLSearchParams(window.location.search));
    RxPlayer.LogLevel = (this.queryParams.logLevel ?? 'NONE').toUpperCase();
    this.isHls = this.queryParams.format === 'hls';
    this.format = this.queryParams.format ?? 'smooth';
    this.licenceUrl = this.queryParams.license;
    this.DRMServiceId = this.queryParams.DRMServiceId;
    //vod
    this.drmId = this.queryParams.drmId;
    this.version = this.queryParams.version;
    this.bifRelativeUrl = this.queryParams.bif;
    this.media = this.queryParams.media;
    this.asset = this.queryParams.asset;

    Object.getOwnPropertyNames(WebTvToolsPlayer.prototype).map((f) => (this[f] = this[f].bind(this)));
  }

  printLog(msg, style = 'text-muted') {
    if (this.state.logs[0]?.msg === msg) {
      return;
    }
    this.setState(({ logs }) => {
      const length = logs.unshift({
        text: `[${dtf.format(Date.now())}] ${msg}`,
        style,
        msg,
      });
      if (length > MAX_LOG_ITEM) {
        logs.pop();
      }
      if (style === 'text-danger') {
        console.error(msg);
      }
      return { logs };
    });
  }

  async getLicense(licenseUri, challenge, contentId, drm, format) {
    const licenseCountLabel = `License[${this.getLicenseCounter++}]`;

    if (licenseUri === undefined || licenseUri.length === 0) {
      licenseUri = this.context.licenseServerUri.get(this.queryParams.licenseVersion ?? '2');
      licenseUri = `${licenseUri}/${drm}`;
    }
    const qsParams = {};

    if (format === 'hls' && contentId !== undefined) {
      const id = contentId.split('/');
      qsParams.rule = id[id.length - 1];
    }
    qsParams.serviceId = this.DRMServiceId;
    if (this.drmId) {
      qsParams.drmId = this.drmId;
    }

    licenseUri += `?${Object.entries(qsParams)
      .map(([k, v]) => `${k}=${v}`)
      .join('&')}`;
    this.printLog(`drm key system: ${drm} contentId: ${contentId} drmId=${this.drmId ?? 'unknown'}`);
    this.printLog(`licenseUri: ${licenseUri}`);

    this.printLog(
      `drm key params "Key internal error": ${this.queryParams.onKeyInternalError ?? fallbackModes.onKeyInternalError.defaultValue}`
    );
    this.printLog(
      `drm key params "Key output restricted": ${
        this.queryParams.onKeyOutputRestricted ?? fallbackModes.onKeyOutputRestricted.defaultValue
      }`
    );
    this.printLog(`drm key params "Key expiration": ${this.queryParams.onKeyExpiration ?? fallbackModes.onKeyExpiration.defaultValue}`);
    this.printLog(`drm key params "Fallback on last try": ${this.queryParams.fallbackOnLastTry ?? fallbackOnLastTry.defaultValue}`);

    const opts = {
      method: 'POST',
      body: challenge,
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    };
    const regexWsDrm = new RegExp(String.raw`webtv-tools(?:-staging)?\.canal(?:-plus\.com|-bis\.com|plus-bo\.net|bis-bo\.net)`, 'i');
    if (regexWsDrm.test(licenseUri)) {
      opts.credentials = 'include';
    }

    let fallbackOnLastTryValue;
    if ('fallbackOnLastTry' in this.queryParams) {
      fallbackOnLastTryValue = this.queryParams.fallbackOnLastTry === 'true';
    } else {
      fallbackOnLastTryValue = fallbackOnLastTry.defaultValue;
    }

    try {
      this.setDownloadsState(licenseCountLabel, { label: licenseCountLabel, state: 'loading' });
      const response = await fetch(licenseUri, opts);
      if (response.ok) {
        this.printLog(`${licenseCountLabel} getLicense success`, 'text-success');
        this.setDownloadsState(licenseCountLabel, 'ok');
        const buffer = await response.arrayBuffer();
        return drm !== 'FairPlay' ? buffer : new Uint8Array(buffer);
      } else {
        this.setDownloadsState(licenseCountLabel, 'warning');

        //get response text
        let text = await response.text();
        text = text.replace(/[\n\r]+/g, ' ');
        //extract text information if available
        const regexp = /<p>(?<speech>.*)<\/p>/.exec(text);
        if (regexp) {
          text = regexp.groups.speech;
        }

        return Promise.reject({
          noRetry: true,
          fallbackOnLastTry: fallbackOnLastTryValue,
          message: `${licenseCountLabel} getLicense error (${response.statusText}) ${text}`,
        });
      }
    } catch (error) {
      this.setDownloadsState(licenseCountLabel, 'ko');
      return Promise.reject({
        noRetry: true,
        fallbackOnLastTry: fallbackOnLastTryValue,
        message: `${licenseCountLabel} getLicense fetch error ${error.message}`,
      });
    }
  }

  getThumbnailIndex(position) {
    const tab = this.parsedBifImages;
    let i = 0;
    while (i < tab.length) {
      const startTime = tab[i].startTime / 1e3;
      if (startTime > position) {
        break;
      }
      i++;
    }
    return i === tab.length ? tab.length - 1 : i;
  }

  updateCurrentThumbnail(position) {
    if (!this.parsedBifImages) {
      return;
    }
    const index = this.getThumbnailIndex(position);

    if (index === this.state.thumbnail.index) {
      return;
    }

    if (this.state.thumbnail.imgSrc) {
      URL.revokeObjectURL(this.state.thumbnail.imgSrc);
    }

    const blob = new Blob([this.parsedBifImages[index].image], { type: 'image/jpeg' });
    const imgSrc = URL.createObjectURL(blob);
    this.setState((prevState) => ({
      thumbnail: {
        ...prevState.thumbnail,
        index,
        imgSrc,
      },
    }));
  }

  async downLoadSubtitles() {
    const rootUrl = this.media.replace(/\.ism\/manifest/i, '');
    this.vodSubtitles = {};

    const promises = Object.keys(vodSubtitlesFormat).map(async (sub) => {
      const subtitleUrl = `${rootUrl}.${sub}`;
      this.setDownloadsState(sub, { state: 'loading', label: `Subtitles file (${sub})` });

      try {
        const response = await fetch(subtitleUrl, { 'Content-Type': 'text/plain' });
        if (!response.ok) {
          throw new TypeError(`download file ko ${response.statusText}`);
        } else {
          this.setDownloadsState(sub, 'ok');
          this.printLog(`get subtitles file [${sub}] ok`, 'text-success');
          this.vodSubtitles[sub] = await response.text();
        }
      } catch (error) {
        this.printLog(`get subtitles [${sub}] fetch error ${error.message}`, 'text-warning');
        this.setDownloadsState(sub, 'ko');
      }
    });
    await Promise.all(promises);
    this.createDownloadedSubtitleList();
  }

  async downLoadBifFile() {
    if (!this.bifRelativeUrl) {
      return;
    }
    const url = this.media.replace(/\/manifest/i, '');
    const bifFileUrl = new URL(this.bifRelativeUrl, url);

    this.setDownloadsState('bif', { state: 'loading', label: 'Thumbnails file (BIF)' });

    try {
      const response = await fetch(bifFileUrl);
      if (!response.ok) {
        throw new TypeError(`download bif file ko ${response.statusText}`);
      } else {
        this.printLog('get bif file ok ', 'text-success');
        this.setDownloadsState('bif', 'ok');
      }
      this.parsedBifImages = parseBifThumbnails(await response.arrayBuffer()).images;
    } catch (error) {
      this.setDownloadsState('bif', 'ko');
      this.printLog(`get bif fetch error ${error.message}`, 'text-warning');
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
    this.player.dispose();
  }

  async componentDidMount() {
    const videoElt = document.getElementById(VIDEO_ELEMENT_ID);
    let serverCertificateUrl = '';

    //Set Player and certificat url
    if (this.isHls) {
      serverCertificateUrl = 'https://secure-webtv-static.canal-plus.com/bourbon/cert/fps-mycanal-1-20161208.der';
    } else {
      serverCertificateUrl = 'https://secure-webtv-static.canal-plus.com/widevine/cert/cert_license_widevine_com.bin';
    }
    this.player = new RxPlayer({
      videoElement: videoElt,
      preferredAudioTracks: [
        {
          language: DEFAULT_AUDIO_TRACK,
          audioDescription: false,
          codec: { all: false, test: /mp4/ },
        },
      ],
      stopAtEnd: false,
    });
    if (!this.isHls) {
      this.player.createDebugElement(document.getElementById('DEBUG_ELEMENT'));
    }

    //Get Certificate
    try {
      const response = await fetch(serverCertificateUrl);
      if (!response.ok) {
        throw new TypeError(`getcertificat error ${response.statusText}`);
      }
      this.certificat = await response.arrayBuffer();
      this.setState({ isLoaded: true });
      this.loadVideo(this.queryParams.license, this.format, this.media, this.certificat, this.state.checkedBoxList);
    } catch (error) {
      this.printLog(`getcertificat fetch error ${error.message}`, 'text-danger');
    }

    //add differents player eventListener
    this.player.addEventListener('error', (error) => {
      const text = `${error.message}  fatal=${error.fatal ? 'YES' : 'NO'}`;
      this.printLog(text, 'text-danger');
      if (error.type === 'NETWORK_ERROR') {
        this.printLog(`Network Error Status Code (${error.status}) for url: ${error.url}`, 'text-danger');
        this.incrementCounter(`http_error_${error.status}`);
      }
      if (error.fatal) {
        this.setDownloadsState('player', 'ko');
      }
    });
    this.player.addEventListener('warning', (error) => {
      const text = `${error.message} fatal=${error.fatal ? 'YES' : 'NO'}`;
      this.printLog(text, 'text-warning');
      if (error.type === 'NETWORK_ERROR') {
        this.printLog(`Network Error Status Code (${error.status}) for url: ${error.url}`, 'text-warning');
        this.incrementCounter(`http_error_${error.status}`);
      }
    });
    this.player.addEventListener('playerStateChange', this.handlePlayerStateChange);
    this.player.addEventListener('videoRepresentationChange', this.handleVideoRepresentationChange);
    this.player.addEventListener('audioRepresentationChange', this.handleAudioRepresentationChange);
    this.player.addEventListener('representationListUpdate', this.handleRepresentationListUpdate);
    this.player.addEventListener('videoTrackChange', this.handleVideoTrackChange);
    this.player.addEventListener('audioTrackChange', this.handleAudioTrackChange);
    this.player.addEventListener('textTrackChange', this.handleTextTrackChange);
    this.player.addEventListener('inbandEvents', this.handleInbandEvents);
    this.player.addEventListener('positionUpdate', this.handlePositionUpdate);
    this.player.addEventListener('availableAudioTracksChange', this.availableAudioTracksChange);
    this.player.addEventListener('availableVideoTracksChange', this.availableVideoTracksChange);
    this.player.addEventListener('availableTextTracksChange', this.availableTextTracksChange);
    this.player.addEventListener('periodChange', this.handlePeriodChange);
    this.player.addEventListener('streamEvent', this.handleStreamEventChange);
    this.player.addEventListener('decipherabilityUpdate', this.handleDecipherabilityUpdate);

    document.addEventListener('keydown', this.onKeyDown);

    //DRM Signalisation listener
    videoElt.addEventListener('webkitneedkey', (evt) => {
      //initDataUri : size (4) + data
      const initDataUri = arrayToString(evt.initData.slice(4));
      this.printLog(`EXT-X-SESSION-KEY uri = ${initDataUri}`, 'text-info');
      this.xSessionKeyUri = initDataUri.split('skd://')[1];
    });
  }

  onKeyDown({ target, key }) {
    if (target.nodeName === 'INPUT') {
      return;
    }
    switch (key) {
      case 'l':
      case ' ':
        this.setState({ showLogs: !this.state.showLogs });
        break;
      case 'p':
        this.player.getPlayerState() === 'PLAYING' ? this.player.pause() : this.player.play();
        break;
      case 'm':
        this.player.isMute() ? this.player.unMute() : this.player.mute();
        this.setState({ isMute: this.player.isMute() });
        break;
      case 'ArrowLeft':
      case 'ArrowRight':
        if (this.state.currentRepresentation !== null) {
          this.player.seekTo({ relative: (key === 'ArrowRight' ? 1 : -1) * (1 / this.state.currentRepresentation.frameRate) });
        }
        break;
      default:
    }
  }

  setDownloadsState(key, val) {
    this.setState((prevState) => {
      let value;
      if (typeof val === 'object') {
        value = val;
      } else {
        value = prevState.downloadsState[key];
        if (value === undefined) {
          return;
        }
        value.state = val;
      }

      return {
        downloadsState: {
          ...prevState.downloadsState,
          [key]: value,
        },
      };
    });
  }

  resetDownloadState() {
    this.setState({ downloadsState: {} });
  }

  loadVideo(licenseUri, format, media, crt, mode) {
    const options = {
      url: media,
      transport: format,
      autoPlay: true,
    };

    this.resetDownloadState();
    this.checkPlaylist();

    const fallbackOnValue = {};
    for (const [k, { defaultValue }] of Object.entries(fallbackModes)) {
      fallbackOnValue[k] = this.queryParams[k] ?? defaultValue;
    }

    if (format !== 'hls') {
      options.manualBitrateSwitchingMode = 'direct';
      options.transportOptions = { aggressiveMode: mode.aggressiveMode };
      options.lowLatencyMode = mode.lowLatencyMode;

      //Manage Key Systems option
      options.keySystems = [];

      for (const k of Object.keys(keySystems)) {
        if (k in this.queryParams ? this.queryParams[k] === keySystems[k].value.toString() : true) {
          options.keySystems.push({
            type: drmSystemInfo[k].domain,
            getLicense: (challenge, contentId) => this.getLicense(licenseUri, challenge, contentId, drmSystemInfo[k].name, format),
            ...fallbackOnValue,
            serverCertificate: k === 'widevine' ? crt : undefined,
          });
        }
      }

      //subtitles format
      options.textTrackMode = 'html';
      options.textTrackElement = document.getElementById('SUBTITLE_ID_ELT');
      //codec transition
      options.onCodecSwitch = 'reload'; //switch to 'continue' after rx-player bug fix
      //options.startAt = { fromLastPosition: -10 };
      //options.networkConfig = { segmentRetry: Infinity };
    } else {
      //HLS
      options.startAt = { fromLastPosition: 0 };
      options.transport = 'directfile';
      options.keySystems = [
        {
          type: 'fairplay',
          getLicense: (challenge) => {
            return this.getLicense(licenseUri, challenge, this.xSessionKeyUri, 'FairPlay', format);
          },
          serverCertificate: crt,
          ...fallbackOnValue,
        },
      ];
    }
    if (format === 'dash') {
      const bindedSetState = this.setState.bind(this);
      options.manifestLoader = (manifestInfo, callback) => manifestLoader(manifestInfo, callback, bindedSetState, this.state.periods);
    }
    console.log(options);
    this.player.loadVideo(options);
    this.setDownloadsState('player', { state: 'loading', label: 'Player' });
  }

  async checkPlaylist() {
    try {
      this.setDownloadsState('playlist', { state: 'loading', label: 'Playlist URL' });
      const response = await fetch(this.media, { credentials: 'same-origin' });
      if (response.ok) {
        this.printLog('check playlist url  ok', 'text-success');
        this.setDownloadsState('playlist', 'ok');
      } else {
        this.printLog('check playlist url  ko', 'text-danger');
        this.setDownloadsState('playlist', 'ko');
      }
    } catch (error) {
      this.printLog('check playlist url  ko', 'text-danger');
      this.setDownloadsState('playlist', 'ko');
    }
  }

  displayLivePosition() {
    const delta = this.player.getLivePosition() - this.player.getPosition(); //in seconde
    const date = new Date(Date.now() - delta * 1e3);
    const strLiveDelta = S2TimeString(delta);
    this.setState({ liveOffset: strLiveDelta });

    const log = `Time[${date.toLocaleDateString()} ${date.toLocaleTimeString()}] liveDelta[${strLiveDelta}]`;
    this.printLog(log, 'text-muted', console.info);
  }

  displayVodPosition() {
    this.setState({ liveOffset: S2TimeString(this.player.getPosition()) });
  }

  displayPLayerPosition() {
    if (this.state.isLive) {
      this.displayLivePosition();
    } else {
      this.displayVodPosition();
    }
  }

  displayUrlCdn() {
    const edgeUrl = this.player.getContentUrls()[0];
    const cdn = getCdnName(edgeUrl);
    this.printLog(`EDGE URL : ${edgeUrl}`);
    this.printLog(`CDN : ${cdn}`);
    this.setState({ cdn, edgeUrl });
  }

  createAudioList(tracks) {
    const audioList = [];
    let currentAudio = '';

    for (const t of tracks) {
      const codec = t.representations[0]?.codec ?? 'NO_CODEC';
      let language = t.label;
      if (!language) {
        language = t.id;
        if (t.language.length !== 0) {
          language = t.language;
        } else if (t.normalized.length !== 0) {
          language = t.normalized;
        }
      }
      if (t.active) {
        currentAudio = t.id;
      }
      audioList.push({
        id: t.id,
        language: `[${codec}] ${language}${t.dub ? '- dub' : ''}`,
      });
    }

    this.setState({ audioList, currentAudio });
  }

  createInStreamSubtitleList() {
    const subtitleList = [];
    let currentSubtitle = '';
    this.player.getAvailableTextTracks().forEach(function (track) {
      const closedCaption = track.closedCaption ? ' Closed Caption' : '';
      let language = track.normalized ? track.normalized : track.language;
      language += closedCaption;
      subtitleList.push({ id: track.id, language: track.label ?? language });
      if (track.active === true) {
        currentSubtitle = track.id;
      }
    });

    this.setState({ subtitleList, currentSubtitle });
  }

  createDownloadedSubtitleList() {
    const subtitleList = [];
    Object.keys(this.vodSubtitles).forEach((key) => {
      subtitleList.push({ id: key, language: vodSubtitlesFormat[key] });
    });

    this.setState({ subtitleList, currentSubtitle: '' });
  }

  incrementCounter(key) {
    this.setState((prevState) => {
      const counters = prevState.counters;
      counters[key] = counters[key] === undefined ? 1 : counters[key] + 1;
      return counters;
    });
  }

  checkNotStartedPictos() {
    const current = new Date((this.state.availabilityStartTime + this.player.getPosition()) * 1e3).getTime();
    this.setState(({ pictos }) => {
      const idToDel = [];
      for (const [id, { start }] of Object.entries(pictos)) {
        if (start > current) {
          idToDel.push(id);
        }
      }
      for (const id of idToDel) {
        delete pictos[id];
      }
      return { pictos };
    });
  }

  //Handle PLAYER EVENT
  handlePlayerStateChange(state) {
    const isLive = this.player.isLive();
    this.printLog(`player state: ${state}`, 'text-primary', console.info);

    this.setState({ playerState: state });
    if (this.isHls) {
      if (state === 'LOADED') {
        this.setDownloadsState('player', 'ok');
      }
      return;
    }

    if (state === 'PLAYING') {
      this.updateSeekBarPosition();
      this.checkNotStartedPictos();
      if (isLive) {
        this.displayLivePosition();
      } else {
        this.displayVodPosition();
        this.startTimer();
      }
    } else if (state === 'LOADED') {
      console.log(this.player.getKeySystemConfiguration());
      //DvrSize & Content Type
      this.setState({
        isLive,
        playlistLoaded: true,
        availabilityStartTime: this.player.getWallClockTime() - this.player.getPosition(),
      });
      this.setDownloadsState('player', 'ok');

      //Subtitles
      if (!isLive && this.format === 'smooth' && this.version === 'VM') {
        //Download subtitles files for vod content & init renderer
        this.downLoadSubtitles();
        this.textTrackRenderer = new TextTrackRenderer({
          videoElement: document.getElementById(VIDEO_ELEMENT_ID),
          textTrackElement: document.getElementById('SUBTITLE_ID_ELT'),
        });
      } else {
        this.createInStreamSubtitleList();
      }

      //Downlaod BIF file
      if (!isLive) {
        this.downLoadBifFile();
      }

      //CDN
      this.displayUrlCdn();
    } else if (!this.state.isLive) {
      this.stopTimer();
    }
  }

  handleVideoRepresentationChange(representation) {
    if (representation !== null) {
      this.printLog(`video bitrate changed: ${representation.bitrate / 1e3} Kbps`, 'text-muted', console.info);
      this.setState({ currentRepresentation: representation });
    }
  }

  handleAudioRepresentationChange({ bitrate }) {
    this.printLog(`audio bitrate changed: ${bitrate / 1e3} kbps`, 'text-muted', console.info);
  }

  handleRepresentationListUpdate({ trackType, period, reason }) {
    if (trackType === 'video' && this.player.getCurrentPeriod()?.id === period.id) {
      const currentVideoTrack = this.player.getVideoTrack();
      if (currentVideoTrack && currentVideoTrack.id === this.player.getVideoTrack(period.id)?.id) {
        this.setState({ representations: currentVideoTrack.representations });
        this.printLog(`video representations list update: ${reason}`, 'text-muted', console.info);
      }
    }
  }

  handleVideoTrackChange(track) {
    if (track !== null) {
      this.setState({ representations: track.representations });
      this.printLog(`video track changed: id=${track.id}`, 'text-muted', console.info);
    }
  }

  handleAudioTrackChange(audioTrack) {
    this.printLog(`audio track changed: ${audioTrack.language}`, 'text-muted', console.info);
    this.setState({ currentAudio: audioTrack.id });
  }

  handleTextTrackChange(textTrack) {
    if (textTrack !== null) {
      this.printLog(`subtitles changed: ${textTrack.language}`, 'text-muted', console.info);
      this.setState({ currentSubtitle: textTrack.id });
    }
  }

  handleDecipherabilityUpdate(data) {
    data.forEach((elt) => {
      const rep = elt.representation;
      if (rep && !rep.decipherable) {
        this.printLog(
          `Representation not decipherable id :"${rep.id}" bitrate: ${rep.bitrate} res: ${rep.width} x  ${rep.height} codec: ${rep.codec} mime: ${rep.mimeType}`,
          'text-warning'
        );
      }
    });
  }

  handleInbandEvents(evts) {
    evts.forEach((evt) => {
      if (evt.type === 'emsg') {
        this.incrementCounter('emsg');
        if (this.state.debugCheckedBoxList.emsg === true) {
          const emsg = evt.value;
          const msg = `emsg schemeIdUri=${emsg.schemeIdUri} value=${emsg.value} id=${emsg.id}`;
          this.printLog(msg, 'text-info');
          console.log(emsg);
        }
      }
    });
  }

  handlePositionUpdate({ duration }) {
    if (!this.isHls) {
      let dvrSize;
      if (this.state.isLive) {
        const dvr = this.player.getMaximumPosition() - this.player.getMinimumPosition();
        dvrSize = S2TimeString(Math.round(dvr / 10) * 10);
      } else if (!Number.isNaN(duration)) {
        dvrSize = S2TimeString(duration);
      }
      if (dvrSize !== undefined && dvrSize !== this.state.dvrSize) {
        this.setState({ dvrSize });
      }
    }
  }

  availableAudioTracksChange(tracks) {
    this.printLog(`availableAudioTracksChange nb = ${tracks.length}`, 'text-muted', console.info);
    this.createAudioList(tracks);
  }

  availableVideoTracksChange(tracks) {
    this.printLog(`availableVideoTracksChange nb = ${tracks.length}`, 'text-muted', console.info);
  }

  availableTextTracksChange(tracks) {
    this.printLog(`availableTextTracksChange nb = ${tracks.length}`, 'text-muted', console.info);
  }

  handlePeriodChange(period) {
    this.setState({ currentPeriodIndex: this.state.periods.findIndex(({ id }) => id === period.id) });
    this.printLog(
      `Period Change start_time:${new Date((this.state.availabilityStartTime + period.start) * 1e3).toLocaleTimeString('fr-FR')}`,
      'text-muted',
      console.info
    );
  }

  handleStreamEventChange(streamEvent) {
    const startDate = new Date((this.state.availabilityStartTime + streamEvent.start) * 1e3);
    const startTime = startDate.toLocaleTimeString('fr-FR');
    const endDate = new Date((this.state.availabilityStartTime + streamEvent.end) * 1000);
    const endTime = endDate.toLocaleTimeString('fr-FR');
    this.printLog(`Stream event changed: start_time: ${startTime}`, 'text-muted', console.info);
    console.log(`Event Start: ${startTime} Stop: ${endTime}`);
    const child = streamEvent?.data?.value?.element?.firstElementChild;
    if (child != null && child.nodeName === 'Metadata' && child.attributes.type.value === 'Overlay') {
      const id = streamEvent.data.value.element.id;
      const attrs = streamEvent.data.value.element.getElementsByTagName('Attribute');
      const elem = [...attrs].find((a) => a.attributes.name.value === 'OVERLAY-NAME');
      if (elem) {
        this.setState((prev) => ({
          ...prev,
          pictos: { ...prev.pictos, [id]: { name: elem.innerHTML, start: startDate.getTime(), end: endDate.getTime() } },
        }));
      }
    }
  }

  //HANDLE USER CONTROL
  handleControlButton(id) {
    const player = this.player;
    const stop = player.getPlayerState() === 'STOPPED' || player.getPlayerState() === 'ENDED';
    if (stop && id !== 'reload') {
      return;
    }

    switch (id) {
      case 'playButton':
        player.getPlayerState() === 'PLAYING' ? player.pause() : player.play();
        break;
      case 'seekMinButton': {
        const offset = this.state.isLive ? 5 : 0;
        setPlayerSeekTo(player, player.getMinimumPosition() + offset);
        break;
      }
      case 'seekLiveButton':
        setPlayerSeekTo(player, this.state.isLive ? player.getLivePosition() - 5 : player.getMaximumPosition() - 5);
        break;
      case 'backwardButton':
        setPlayerSeek(player, -10);
        break;
      case 'forwardButton':
        setPlayerSeek(player, 10);
        break;
      case 'fastBackwardButton':
        setPlayerSeek(player, -300);
        break;
      case 'fastForwardButton':
        setPlayerSeek(player, 300);
        break;
      case 'reload':
        this.printLog('Reloading...', 'text-info');
        this.setState({ counters: {} });
        this.player.stop();
        this.loadVideo(undefined, this.format, this.media, this.certificat, this.state.checkedBoxList);
        break;
      case 'Muting':
        this.player.isMute() ? this.player.unMute() : this.player.mute();
        this.setState({ isMute: this.player.isMute() });
        break;
      default:
      //nothing to do;
    }
    if (id !== 'playButton' && id !== 'reload') {
      this.displayPLayerPosition();
      this.updateSeekBarPosition();
    }
  }

  handleAudioSelection(e) {
    this.player.setAudioTrack(e.target.value);
  }

  handleBitrateMode(e) {
    const bitrateMode = e.target.value;
    this.player.unlockVideoRepresentations();
    this.setState({ bitrateMode });
    if (bitrateMode === 'Manual') {
      this.handleVideoSelection(null, true);
    }
  }

  handleVideoSelection(e, isManual) {
    if (this.state.representations.length > 0) {
      let repr;
      if (isManual === true) {
        repr = this.state.representations.reduce((acc, r) => (acc.bitrate > r.bitrate ? acc : r));
      } else {
        const bitrate = Number(e.target.value);
        repr = this.state.representations.find((r) => r.bitrate === bitrate);
      }
      if (repr !== undefined && this.state.currentRepresentation?.id !== repr.id) {
        this.player.lockVideoRepresentations([repr.id]);
      }
    }
  }

  handleInStreamSubtitlesSelection(value) {
    if (value === 'disabled') {
      this.player.disableTextTrack();
      this.setState({ currentSubtitle: 'disabled' });
      this.printLog('subtitles disabled', 'text-muted', console.info);
    } else {
      this.player.setTextTrack(value);
    }
  }

  setDownloadedSubtitles(value) {
    try {
      this.textTrackRenderer.setTextTrack({
        data: this.vodSubtitles[value],
        type: vodSubtitlesFormat[value],
        language: 'fr-FR',
        // timeOffset: 2.3, // optional offset in seconds to add to the subtitles
      });
    } catch (e) {
      console.error(`Could not parse the subtitles: ${e}`);
    }
  }

  handleDownloadedSubtitlesSelection(value) {
    if (value === 'disabled') {
      this.textTrackRenderer.removeTextTrack();
      this.setState({ currentSubtitle: 'disabled' });
      this.printLog('subtitles disabled', 'text-muted', console.info);
    } else {
      this.setDownloadedSubtitles(value);
      this.setState({ currentSubtitle: value });
      this.printLog(`vod subtitles changed format ${vodSubtitlesFormat[value]}`, 'text-muted', console.info);
    }
  }

  handleSubtitleSelection(e) {
    const value = e.target.value;
    if (!this.state.isLive && this.format === 'smooth') {
      this.handleDownloadedSubtitlesSelection(value);
    } else {
      this.handleInStreamSubtitlesSelection(value);
    }
  }

  /*Handle events for player seek*/
  handleSeekChange(e) {
    const min = this.player.getMinimumPosition();
    const max = this.player.getMaximumPosition();
    let delta;
    if (this.state.isLive) {
      delta = Math.round((max - min) * (1 - e.target.value / 100));
    } else {
      delta = Math.round((max - min) * (e.target.value / 100));
    }

    this.setState({
      seekValue: e.target.value,
      liveOffset: S2TimeString(delta),
    });
  }

  handleSeekUp(e) {
    const min = this.player.getMinimumPosition();
    const max = this.state.isLive ? this.player.getLivePosition() : this.player.getMaximumPosition();
    try {
      this.player.seekTo({
        position: Math.round(min + (max - min) * (e.target.value / 100)),
      });
    } catch (e) {
      this.printLog(e, 'text-danger');
    }
  }

  updateSeekBarPosition() {
    const min = this.player.getMinimumPosition();
    const max = this.state.isLive ? this.player.getLivePosition() : this.player.getMaximumPosition();
    this.setState({
      seekValue: Math.round(((this.player.getPosition() - min) * 100) / (max - min)),
    });
  }
  /*Handles events for bif thumbnail display*/

  handleThumbnailMove(e) {
    if (!this.player || this.state.isLive) {
      return;
    }
    //current position of the mouse over the seekbar
    const x = e.nativeEvent.offsetX;
    //max size of seekbar
    const xMax = e.target.clientWidth;
    //thumbnail offset position
    const offset = x <= THUMBNAIL_WIDTH / 2 ? 0 : x + THUMBNAIL_WIDTH / 2 > xMax ? xMax - THUMBNAIL_WIDTH : x - THUMBNAIL_WIDTH / 2;

    this.setState((prevState) => ({
      thumbnail: {
        ...prevState.thumbnail,
        offsetX: `${offset}px`,
      },
    }));

    const delta = Math.round((this.player.getMaximumPosition() - this.player.getMinimumPosition()) * (x / xMax));
    this.updateCurrentThumbnail(delta);
  }

  handleThumbnailEnter() {
    if (!this.player || this.state.isLive) {
      return;
    }

    this.setState((prevState) => ({
      thumbnail: {
        ...prevState.thumbnail,
        display: true,
      },
    }));
  }

  handleThumbnailOut() {
    if (!this.player || this.state.isLive) {
      return;
    }

    this.setState((prevState) => ({
      thumbnail: {
        ...prevState.thumbnail,
        display: false,
      },
    }));
  }

  handleCheckBox(evt) {
    const { name, checked } = evt.target;
    this.setState({ checkedBoxList: { ...this.state.checkedBoxList, [name]: checked } }, () => {
      this.printLog(`aggresiveMode : ${this.state.checkedBoxList.aggressiveMode}`);
      this.player.stop();
      this.loadVideo(this.licenceUrl, this.format, this.media, this.certificat, this.state.checkedBoxList);
    });
  }

  handleDebugCheckBox(evt) {
    const { name, checked } = evt.target;
    this.setState({ debugCheckedBoxList: { ...this.state.debugCheckedBoxList, [name]: checked } });
  }

  nextPeriod() {
    setPlayerSeekTo(this.player, this.state.periods[this.state.currentPeriodIndex - 1].start);
  }

  prevPeriod() {
    setPlayerSeekTo(this.player, this.state.periods[this.state.currentPeriodIndex + 1].start);
  }

  handlePeriodClick(time) {
    setPlayerSeekTo(this.player, time - PERIOD_TIME_OFFSET);
  }

  //Manage timer to seekbar position for vod content
  timer() {
    this.displayVodPosition();
    this.updateSeekBarPosition();
  }

  startTimer() {
    this.intervalHandle = setInterval(this.timer, 1e3);
  }

  stopTimer() {
    clearInterval(this.intervalHandle);
  }

  updateShowPanel(key) {
    const newValue = { conf: false, drm: false };
    this.setState((prevState) => {
      //previous state close => open 'key' panel
      if (!prevState.showPanel[key]) {
        newValue[key] = true;
      }
      return { showPanel: newValue };
    });
  }

  playerOptionsPanel() {
    return (
      <CCard border='dark' title={<b className='text-primary'>Player options</b>} open>
        <Stack gap='3'>
          <Form.Group>
            <Form.Label className='fw-bold'>Language</Form.Label>
            <Form.Select onChange={this.handleAudioSelection} size='sm'>
              {this.state.audioList &&
                this.state.audioList.map((audio) => (
                  <option key={audio.id} value={audio.id}>
                    {audio.language}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label className='fw-bold'>Subtitles</Form.Label>
            <Form.Select onChange={this.handleSubtitleSelection} size='sm'>
              <option value='disabled'>disabled</option>
              {this.state.subtitleList &&
                this.state.subtitleList.map((track) => (
                  <option key={track.id} value={track.id}>
                    {track.language}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label className='fw-bold'>Bitrate mode</Form.Label>
            <Form.Select onChange={this.handleBitrateMode} size='sm'>
              <option>Auto</option>
              <option disabled={this.state.representations.length === 0}>Manual</option>
            </Form.Select>
          </Form.Group>
          {this.state.bitrateMode === 'Manual' && this.state.currentRepresentation !== null && (
            <Form.Group>
              <Form.Label className='fw-bold'>Bitrate (kbps)</Form.Label>
              <Form.Select onChange={this.handleVideoSelection} size='sm' value={this.state.currentRepresentation.bitrate}>
                {this.state.representations.map(({ bitrate }) => (
                  <option key={bitrate} value={bitrate}>
                    {bitrate / 1e3}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label className='fw-bold'>Play mode</Form.Label>
            {Object.entries(playerModeCheckBoxList).map(([k, { label, popup }]) => (
              <Tooltiped key={label} text={popup}>
                <Form.Check checked={this.state.checkedBoxList[k]} name={k} label={label} onChange={this.handleCheckBox} />
              </Tooltiped>
            ))}
          </Form.Group>
          <RxPlayerLogLevelComponent logLevel={RxPlayer.LogLevel} />
          <Form.Group>
            <Form.Label className='fw-bold'>Debug options</Form.Label>
            {Object.entries(debugCheckBoxList).map(([k, { label, popup }]) => (
              <Tooltiped key={label} text={popup}>
                <Form.Check
                  type='switch'
                  checked={this.state.debugCheckedBoxList[k]}
                  name={k}
                  label={label}
                  onChange={this.handleDebugCheckBox}
                />
              </Tooltiped>
            ))}
          </Form.Group>
        </Stack>
      </CCard>
    );
  }

  pictosCard(pos) {
    const pictos = Object.entries(this.state.pictos).sort(([, a], [, b]) => b.end - a.end);

    if (pictos.length === 0) {
      return null;
    }

    const current = new Date((this.state.availabilityStartTime + pos) * 1e3).getTime();

    return (
      <CCard title={<b className='text-success'>Metadata overlays</b>} border='success' open body={false}>
        <ListGroup size='sm' variant='flush'>
          {pictos.map(([id, { name, end }]) => (
            <ListGroup.Item key={id}>
              <Tooltiped text={`End: ${new Date(end).toLocaleString()}`}>
                <span className={end < current ? 'text-decoration-line-through' : 'text-success fader'}>
                  <b>[{id}]</b> {name}
                </span>
              </Tooltiped>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </CCard>
    );
  }

  hlsVideoPanel() {
    return (
      <Card.Body className='p-0'>
        <Ratio aspectRatio='16x9'>
          <video controls id={VIDEO_ELEMENT_ID} />
        </Ratio>
      </Card.Body>
    );
  }

  otherVideoPanel() {
    const displayOSD =
      this.state.playlistLoaded && ['PAUSED', 'LOADED', 'SEEKING', 'BUFFERING', 'FREEZING'].includes(this.state.playerState);

    return (
      <>
        <Card.Body className='p-0'>
          <Button onClick={() => this.handleControlButton('playButton')} variant='dark' id='playButtonInVideo'>
            {displayOSD && (
              <i
                className={['PAUSED', 'LOADED'].includes(this.state.playerState) ? 'fas fa-play' : 'fas fa-spinner fa-spin'}
                id='playIconInVideo'
              />
            )}
            {this.thumbnailPanel()}
            <Ratio aspectRatio='16x9'>
              <>
                <div
                  id='DEBUG_ELEMENT'
                  style={{ display: this.state.debugCheckedBoxList.debugPanel ? null : 'none', textAlign: 'left', zIndex: 4 }}
                />
                <video id={VIDEO_ELEMENT_ID} />
                <div id='SUBTITLE_ID_ELT' />
              </>
            </Ratio>
          </Button>
        </Card.Body>
        <Card.Footer>
          <Stack gap='2' className='mt-2'>
            <input
              type='range'
              min='0'
              max='100'
              step='0.01'
              value={this.state.seekValue}
              onChange={this.handleSeekChange}
              onMouseUp={this.handleSeekUp}
              onMouseEnter={this.handleThumbnailEnter}
              onMouseMove={this.handleThumbnailMove}
              onMouseOut={this.handleThumbnailOut}
            />
            <Row>
              <Col md='4'>
                <InputComponent text={this.state.isLive ? 'Live offset' : 'Position'}>
                  <Form.Control readOnly value={`${this.state.liveOffset} / ${this.state.dvrSize}`} />
                </InputComponent>
              </Col>
              {this.player && this.state.isLive && (
                <Col md='4' className='ms-auto'>
                  <CurrentTimeComponent player={this.player} />
                </Col>
              )}
            </Row>
            <div className='mx-auto'>
              <Stack direction='horizontal' gap='2'>
                {this.state.periods.length > 1 && (
                  <ButtonGroup>
                    <TooltipedButton
                      text='previous Period'
                      onClick={this.prevPeriod}
                      variant='outline-success'
                      disabled={this.state.currentPeriodIndex === this.state.periods.length - 1}>
                      <i className='fas fa-angle-left' />
                    </TooltipedButton>
                    <TooltipedButton
                      text='next Period'
                      variant='outline-success'
                      onClick={this.nextPeriod}
                      disabled={this.state.currentPeriodIndex === 0}>
                      <i className='fas fa-angle-right' />
                    </TooltipedButton>
                  </ButtonGroup>
                )}
                <ButtonGroup>
                  <TooltipedButton
                    text={this.state.isLive ? 'Buffer start' : 'start'}
                    onClick={() => this.handleControlButton('seekMinButton')}>
                    <i className='fas fa-fast-backward' />
                  </TooltipedButton>
                  <TooltipedButton text='-5m' onClick={() => this.handleControlButton('fastBackwardButton')}>
                    <i className='fas fa-backward' />
                  </TooltipedButton>
                  <TooltipedButton text='-10s' onClick={() => this.handleControlButton('backwardButton')}>
                    <i className='fas fa-caret-left' />
                  </TooltipedButton>
                  <TooltipedButton text='Play / Pause' onClick={() => this.handleControlButton('playButton')}>
                    <i className={['PAUSED', 'LOADED'].includes(this.state.playerState) ? 'fas fa-play' : 'fas fa-pause'} />
                  </TooltipedButton>
                  <TooltipedButton text='+10s' onClick={() => this.handleControlButton('forwardButton')}>
                    <i className='fas fa-caret-right' />
                  </TooltipedButton>
                  <TooltipedButton text='+5m' onClick={() => this.handleControlButton('fastForwardButton')}>
                    <i className='fas fa-forward' />
                  </TooltipedButton>
                  <TooltipedButton text={this.state.isLive ? 'Live' : 'End'} onClick={() => this.handleControlButton('seekLiveButton')}>
                    <i className='fas fa-fast-forward' />
                  </TooltipedButton>
                </ButtonGroup>
                <ButtonGroup>
                  <TooltipedButton text='Mute / Unmute' variant='outline-dark' onClick={() => this.handleControlButton('Muting')}>
                    <i className={this.state.isMute ? 'fas fa-volume-mute' : 'fas fa-volume-up'} />
                  </TooltipedButton>
                  <TooltipedButton text='FullScreen' variant='outline-dark' onClick={() => ToggleFullScreen()}>
                    <i className='fas fa-expand' />
                  </TooltipedButton>
                  <TooltipedButton text='Reload video' variant='outline-dark' onClick={() => this.handleControlButton('reload')}>
                    <i className='fas fa-redo' />
                  </TooltipedButton>
                </ButtonGroup>
              </Stack>
            </div>
          </Stack>
        </Card.Footer>
      </>
    );
  }

  playerPanel() {
    const link = `${analysisRootUrl[this.format]}${window.location.search}`;

    return (
      <Card border='dark'>
        <Card.Header className='px-2 py-1'>
          <Stack direction='horizontal' gap='2'>
            <ButtonGroup size='sm' className='me-auto'>
              <AnalysisButton as={Link} to={link} />
              <DownloadButton href={this.media} />
              <CopyButton text={this.media} copyText='copy url to clipboard' />
            </ButtonGroup>
            <Badge bg='primary' className='mx-auto mb-0 fs-6'>
              {this.asset}
            </Badge>
            <Badge bg='primary' className='ms-auto mb-0 fs-6'>
              {this.state.cdn}
            </Badge>
            {this.state.currentRepresentation !== null && (
              <Badge className='mb-0 fs-6'>{this.state.currentRepresentation.bitrate / 1e3} kbps</Badge>
            )}
          </Stack>
        </Card.Header>
        {this.isHls ? this.hlsVideoPanel() : this.otherVideoPanel()}
      </Card>
    );
  }

  thumbnailPanel() {
    return (
      <>
        {this.state.thumbnail.display && this.state.thumbnail.imgSrc && (
          <Image src={this.state.thumbnail.imgSrc} style={{ left: `${this.state.thumbnail.offsetX}` }} thumbnail id='BIF_THUMBNAIL_ELT' />
        )}
      </>
    );
  }

  render() {
    return (
      <Row className='gx-1'>
        <Col md='auto'>
          <Stack gap='1'>
            {!this.isHls && this.playerOptionsPanel()}
            <AdditionalPlayerOptions isHls={this.isHls} />
          </Stack>
        </Col>
        <Col>
          <Stack gap='1'>
            {this.playerPanel()}
            {this.state.edgeUrl && <UrlComponent url={this.state.edgeUrl} cdn={this.state.cdn} />}
            {this.state.periods.length > 0 && this.state.downloadsState.player.state !== 'ko' && this.state.currentPeriodIndex !== -1 && (
              <PeriodComponent
                periods={this.state.periods}
                callbackParent={this.handlePeriodClick}
                availabilityStartTime={this.state.availabilityStartTime}
                currentPeriod={this.state.periods[this.state.currentPeriodIndex]}
              />
            )}
          </Stack>
        </Col>
        <Col md='auto'>
          <Stack gap='1'>
            <StatusListComponent list={this.state.downloadsState} />
            {this.state.debugCheckedBoxList.debugPanel && <BufferGapComponent player={this.player} />}
            {Object.keys(this.state.counters).length > 0 && (
              <KVCard title={<b className='text-primary'>emsg, HTTP errors</b>} border='dark' obj={this.state.counters} body={false} open />
            )}
            {this.pictosCard(this.player?.getPosition())}
            <PlayerLogsCanvas
              showLogs={this.state.showLogs}
              onClick={() => this.setState({ showLogs: true })}
              onHide={() => this.setState({ showLogs: false })}
              player={this.player}
              logs={this.state.logs}
            />
          </Stack>
        </Col>
      </Row>
    );
  }
}

export default WebTvToolsPlayer;
